import {
  TextField,
  SubmitButton,
  postalCodeString,
} from "@curaleaf-international/components";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as yup from "yup";

import AddressPicker from "src/components/Onboarding/AddressPicker";
import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import SplitPage from "src/components/Onboarding/SplitPage";
import {
  usePendingAssistedReferralQuery,
  usePatientQuery,
  useUpdatePendingARAddressMutation,
  useUpdateOnboardingStatusMutation,
} from "src/queries";

const CHARACTER_LIMIT = 32;

interface IForm {
  addressLine1: string;
  addressLine2: string;
  city: string;
  county: string;
  country: string;
  postcode: string;
}

const validationSchema = yup.object({
  addressLine1: yup.string().max(CHARACTER_LIMIT).required(),
  addressLine2: yup.string().max(CHARACTER_LIMIT).required(),
  city: yup.string().max(CHARACTER_LIMIT).required(),
  county: yup.string().max(CHARACTER_LIMIT).required(),
  country: yup.string().max(CHARACTER_LIMIT).required(),
  postcode: postalCodeString()
    .required()
    .test(
      "channelIslands",
      "We are unable to treat patients from Guernsey or Jersey",
      (value) => {
        return !value.startsWith("GY") && !value.startsWith("JE");
      },
    ),
});

const Address = () => {
  const { data: patient } = usePatientQuery();
  const { data: assistedReferral } = usePendingAssistedReferralQuery();
  const { mutateAsync: update } = useUpdatePendingARAddressMutation();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const [_, setLocation] = useLocation();

  const defaultValues = {
    addressLine1: assistedReferral?.addressLine1 ?? "",
    addressLine2: assistedReferral?.addressLine2 ?? "",
    city: assistedReferral?.city ?? "",
    county: assistedReferral?.county ?? "",
    country: assistedReferral?.country ?? "",
    postcode: assistedReferral?.postcode ?? "",
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    const values = Object.values(methods.getValues());
    if (values.every((value) => value === "")) {
      methods.reset(defaultValues);
    }
  }, [assistedReferral, methods.reset]);

  const onSelect = (data: IForm) => {
    methods.reset(data);
  };

  const onSubmit = async (data: IForm) => {
    await update(data);

    if (patient!.onboardingStatus === "userAddress") {
      await updateOnboardingStatus("healthInfo");
    }
    setLocation("/onboarding/health/");
  };

  return (
    <SplitPage
      left={<OnboardingStepper />}
      right={
        <Stack spacing={2}>
          <Typography variant="h2">Address</Typography>
          <Card>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CardContent>
                  <Stack spacing={2}>
                    <AddressPicker onSelect={onSelect} />
                    <Divider />
                    <TextField
                      fullWidth
                      label="Address line 1"
                      name="addressLine1"
                      limit={CHARACTER_LIMIT}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Address line 2"
                      name="addressLine2"
                      limit={CHARACTER_LIMIT}
                      required
                    />
                    <TextField
                      fullWidth
                      label="City"
                      name="city"
                      limit={CHARACTER_LIMIT}
                      required
                    />
                    <TextField
                      fullWidth
                      label="County"
                      name="county"
                      limit={CHARACTER_LIMIT}
                      required
                    />
                    <TextField
                      fullWidth
                      label="Postcode"
                      name="postcode"
                      required
                    />
                    <TextField
                      fullWidth
                      label="Country"
                      name="country"
                      limit={CHARACTER_LIMIT}
                      required
                    />
                  </Stack>
                </CardContent>
                <CardActions>
                  <SubmitButton
                    disabled={patient === undefined}
                    fullWidth
                    label="Continue"
                  />
                </CardActions>
              </form>
            </FormProvider>
          </Card>
        </Stack>
      }
      title="Address"
    />
  );
};

export default Address;
