declare global {
  interface Window {
    dataLayer: Record<string, any>[];
    fbq?: (t: string, e: string) => void;
    snaptr?: (t: string, e: string) => void;
  }
}

export const fbTrack = (eventName: string) => {
  if (window.fbq) {
    window.fbq("trackCustom", eventName);
  }
};

export const snapTrack = (eventName: string) => {
  if (window.snaptr) {
    window.snaptr("track", eventName);
  }
};

export const tagEvent = (eventName: string, data: any) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: `CE:${eventName}`,
    ...data,
  });
};

export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));
