import { ToastContext } from "@curaleaf-international/components";
import * as Sentry from "@sentry/react";
import { getRedirectResult, onAuthStateChanged } from "firebase/auth";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";

import { auth } from "src/firebase";
import { AuthUser, newAuthUser } from "src/models";

interface IAuth {
  authenticate: (user: AuthUser) => void;
  user: AuthUser | null | undefined;
  unauthenticate: () => void;
}

export const AuthContext = createContext<IAuth>({
  authenticate: () => {},
  user: undefined,
  unauthenticate: () => {},
});

interface IProps {
  children?: ReactNode;
}

export const AuthContextProvider = ({ children }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [user, setUser] = useState<AuthUser | null | undefined>(undefined);

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (fireUser) => {
      if (fireUser) {
        setUser(newAuthUser(fireUser));
        Sentry.setUser({ id: fireUser.uid });
      } else {
        setUser(null);
        Sentry.setUser(null);
      }
    });

    return () => {
      listener();
    };
  }, [auth]);

  useEffect(() => {
    const initialise = async () => {
      try {
        const result = await getRedirectResult(auth);
        if (result !== null) {
          setUser(newAuthUser(result.user));
        }
      } catch (error: any) {
        if (error.message.includes("NHS login")) {
          addToast("Please use NHS login to sign in", "error");
        } else {
          addToast(
            "Something went wrong. Please try again or use a different sign-in option.",
            "error",
          );
        }
      }
    };
    void initialise();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        authenticate: (user: AuthUser) => setUser(user),
        user,
        unauthenticate: () => setUser(null),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
