import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@fontsource/inter/900.css";
import {
  ToastContextProvider,
  Toasts,
} from "@curaleaf-international/components";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { AuthContextProvider } from "src/AuthContext";
import Router from "src/Router";
import ThemeProvider from "src/ThemeProvider";

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ToastContextProvider>
        <AuthContextProvider>
          <HelmetProvider>
            <Helmet>
              <title>Curaleaf Clinic</title>
            </Helmet>
            <ThemeProvider>
              <ToastContextProvider>
                <Toasts />
                <Router />
              </ToastContextProvider>
            </ThemeProvider>
          </HelmetProvider>
        </AuthContextProvider>
      </ToastContextProvider>
    </QueryClientProvider>
  );
};

export default App;
