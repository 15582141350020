import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";

import { db } from "src/firebase";
import { PartnerPharmacy, newPartnerPharmacy } from "src/models";
import { useQuery } from "src/query";

export const usePartnerPharmaciesQuery = () => {
  return useQuery<PartnerPharmacy[]>({
    queryKey: ["partnerPharmacies"],
    queryFn: async () => {
      const snapshot = await getDocs(
        query(collection(db, "Partners/"), where("Active", "==", true)),
      );
      return snapshot.docs.map((item) =>
        newPartnerPharmacy({ id: item.id, ...item.data() }),
      );
    },
  });
};

export const usePartnerPharmacyQuery = (
  partnerCode: string | null | undefined,
) => {
  return useQuery<PartnerPharmacy>({
    queryKey: ["partnerPharmacies", partnerCode],
    queryFn: async () => {
      const snapshot = await getDoc(doc(db, "Partners", partnerCode!));
      return newPartnerPharmacy({ id: snapshot.id, ...snapshot.data() });
    },
    enabled:
      partnerCode !== undefined && partnerCode !== null && partnerCode !== "",
  });
};
