import { useDebounce } from "@curaleaf-international/components";
import { Loader } from "@googlemaps/js-api-loader";
import Autocomplete from "@mui/material/Autocomplete";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState } from "react";

import Search from "src/components/Onboarding/Search";

interface IAddress {
  addressLine1: string;
  addressLine2: string;
  city: string;
  county: string;
  country: string;
  postcode: string;
}

interface IProps {
  onSelect: (data: IAddress) => void;
}

const AddressPicker = ({ onSelect }: IProps) => {
  const [loadingStatus, setLoadingStatus] = useState("LOADING");
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const [predictions, setPredictions] = useState<string[]>([]);

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyC-LRDkw2yNL7jNtQshJf0Lm3z1jO2DMQA",
      libraries: ["places"],
    });
    void loader.load().then(
      () => setLoadingStatus("SUCCESS"),
      () => setLoadingStatus("FAILURE"),
    );
  }, []);

  useEffect(() => {
    if (search !== "") {
      const service = new window.google.maps.places.AutocompleteService();
      void service
        .getPlacePredictions({
          input: search,
          componentRestrictions: {
            country: ["gb", "gg", "ie", "im", "je"],
          },
        })
        .then((result) => {
          setPredictions(
            result.predictions.map((prediction) => prediction.description),
          );
        });
    }
  }, [search]);

  if (loadingStatus === "LOADING") {
    return <Skeleton height="60px" width="100%" />;
  } else if (loadingStatus === "FAILURE") {
    return null;
  } else {
    const onChange = async (address: string) => {
      if (!address) return;
      const geocoder = new window.google.maps.Geocoder();
      const result = await geocoder.geocode({ address });
      const data = result.results[0].address_components;

      onSelect({
        addressLine1: `${
          data.find((item) => item.types.includes("street_number"))
            ?.long_name ?? ""
        } ${
          data.find((item) => item.types.includes("route"))?.long_name ?? ""
        }`.trim(),
        addressLine2: data.find((item) =>
          item.types.includes("sublocality_level_1"),
        )
          ? (data.find((item) => item.types.includes("sublocality_level_1"))
              ?.long_name ?? "")
          : (data.find((item) => item.types.includes("locality"))?.long_name ??
            ""),
        city:
          data.find((item) => item.types.includes("postal_town"))?.long_name ??
          "",
        county:
          data.find((item) =>
            item.types.includes("administrative_area_level_2"),
          )?.long_name ?? "",
        country:
          data.find((item) =>
            item.types.includes("administrative_area_level_1"),
          )?.long_name ?? "",
        postcode:
          data.find((item) => item.types.includes("postal_code"))?.long_name ??
          "",
      });
    };

    return (
      <>
        <Autocomplete
          autoComplete
          filterOptions={(x) => x}
          filterSelectedOptions
          getOptionLabel={(option) => option}
          noOptionsText="No locations"
          onChange={async (_event: any, newValue: any) => {
            await onChange(newValue);
          }}
          options={predictions}
          onInputChange={(_event, newInputValue) => {
            setRawSearch(newInputValue);
          }}
          renderInput={(params) => (
            <Search {...params} fullWidth label="Start typing your address" />
          )}
          renderOption={(props, option) => <li {...props}>{option}</li>}
        />
      </>
    );
  }
};

export default AddressPicker;
