import { ref, uploadBytesResumable } from "firebase/storage";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { storage } from "src/firebase";
import { useMutation } from "src/query";

export const useUploadSCRSignatureMutation = () => {
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (blob: Blob) => {
      const fileRef = ref(
        storage,
        `/SCRConsentSignatures/${user!.id}/signature`,
      );

      await uploadBytesResumable(fileRef, blob);
    },
  });
};
