import { z } from "zod";

const partnerPharmacySchema = z
  .object({
    id: z.string(),
    FullPrescriptionAddress: z.object({
      Name: z.string(),
      AddressLine1: z.string(),
      AddressLine2: z.string(),
      AddressLine3: z.string().nullable(),
      PostCode: z.string(),
      Country: z.string(),
      FAO: z.string().nullable(),
    }),
  })
  .transform((value) => ({
    code: value.id,
    addressLine1: value.FullPrescriptionAddress.AddressLine1,
    addressLine2: value.FullPrescriptionAddress.AddressLine2,
    addressLine3: value.FullPrescriptionAddress.AddressLine3,
    name: value.FullPrescriptionAddress.Name,
    postcode: value.FullPrescriptionAddress.PostCode,
  }));

export type PartnerPharmacy = z.infer<typeof partnerPharmacySchema>;

export const newPartnerPharmacy = (data: unknown): PartnerPharmacy =>
  partnerPharmacySchema.parse(data);
