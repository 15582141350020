import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

const production = {
  apiKey: "AIzaSyA05Wl7IPeVRmwConlhqiUL1JPqxR7kqPM",
  authDomain: "app.curaleafclinic.com",
  databaseURL: "https://sapphire-medical.firebaseio.com",
  projectId: "sapphire-medical",
  storageBucket: "sapphire-medical.appspot.com",
  messagingSenderId: "342095796125",
  appId: "1:342095796125:web:7b16160cf8c6ef586bc174",
  measurementId: "G-HPP7F9VZWJ",
};

const development = {
  apiKey: "AIzaSyCUQYW3SA27VJRkINHMltb3k8w9o0X3uT4",
  authDomain: "sapphire-development.firebaseapp.com",
  projectId: "sapphire-development",
  storageBucket: "sapphire-development.appspot.com",
  messagingSenderId: "808427061898",
  appId: "1:808427061898:web:10514a8345b88dc019a373",
  measurementId: "G-1CNYTVEMHC",
  databaseURL: "https://sapphire-development.firebaseio.com",
};

export const app = initializeApp(
  import.meta.env.DEV ? development : production,
);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, "europe-west2");
export const storage = getStorage();
