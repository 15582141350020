import useMediaQuery from "@mui/material/useMediaQuery";

interface IProps {
  ariaHidden?: boolean;
}

const AppleLogo = ({ ariaHidden = undefined }: IProps) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  return (
    <svg
      aria-hidden={ariaHidden}
      fill={prefersDarkMode ? "#FFFFFF" : "#000000"}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="21px"
      height="21px"
    >
      <path d="M 16.125 1 C 14.972 1.067 13.648328 1.7093438 12.861328 2.5273438 C 12.150328 3.2713438 11.589359 4.3763125 11.818359 5.4453125 C 13.071359 5.4783125 14.329031 4.8193281 15.082031 3.9863281 C 15.785031 3.2073281 16.318 2.12 16.125 1 z M 16.193359 5.4433594 C 14.384359 5.4433594 13.628 6.5546875 12.375 6.5546875 C 11.086 6.5546875 9.9076562 5.5136719 8.3476562 5.5136719 C 6.2256562 5.5146719 3 7.4803281 3 12.111328 C 3 16.324328 6.8176563 21 8.9726562 21 C 10.281656 21.013 10.599 20.176969 12.375 20.167969 C 14.153 20.154969 14.536656 21.011 15.847656 21 C 17.323656 20.989 18.476359 19.367031 19.318359 18.082031 C 19.922359 17.162031 20.170672 16.692344 20.638672 15.652344 C 17.165672 14.772344 16.474672 9.1716719 20.638672 8.0136719 C 19.852672 6.6726719 17.558359 5.4433594 16.193359 5.4433594 z" />
    </svg>
  );
};
export default AppleLogo;
