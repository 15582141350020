import {
  EmailField,
  LazyPasswordWithStrengthField,
  SubmitButton,
  ToastContext,
  PasswordField,
} from "@curaleaf-international/components";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as yup from "yup";

import AppleSignInButton from "src/components/Onboarding/AppleSignInButton";
import GoogleSignInButton from "src/components/Onboarding/GoogleSignInButton";
import MicrosoftSignInButton from "src/components/Onboarding/MicrosoftSignInButton";
import NHSSignInButton from "src/components/Onboarding/NHSSignInButton";
import ProcessSidebar from "src/components/Onboarding/ProcessSidebar";
import SplitPage from "src/components/Onboarding/SplitPage";
import { useSignUpMutation } from "src/queries";

interface IForm {
  confirmPassword: string;
  email: string;
  password: string;
}

const validationSchema = yup.object({
  confirmPassword: yup
    .string()
    .required("Please retype your password.")
    .oneOf([yup.ref("password")], "Passwords must match"),
  email: yup.string().email().required(),
  password: yup.string().required(),
});

const SignUp = () => {
  const { addToast } = useContext(ToastContext);
  const [_, setLocation] = useLocation();
  const { mutateAsync: signUp } = useSignUpMutation();

  const methods = useForm<IForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      confirmPassword: "",
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data: IForm) => {
    try {
      await signUp(data);
      setLocation("/email-unverified/");
    } catch {
      addToast(
        "Unable to create an account. Please try signing in or try signing up again later.",
        "error",
      );
    }
  };

  return (
    <>
      <SplitPage
        invert={true}
        left={<ProcessSidebar />}
        right={
          <Box
            sx={{
              marginLeft: { sm: 10, xs: 0 },
              marginRight: { sm: 10, xs: 0 },
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h2">Create an Account</Typography>
              <Card variant="onboarding">
                <CardContent>
                  <Typography gutterBottom variant="body1">
                    At Curaleaf Clinic we specialise in high-quality medical
                    cannabis care. Our experts are here to help you feel
                    confident and empowered in managing your condition.
                  </Typography>
                  <Typography gutterBottom variant="body1">
                    Check your eligibility for a consultation by creating an
                    account below.
                  </Typography>
                  <Typography
                    variant="body1"
                    color="primary"
                    sx={{ fontWeight: "600" }}
                  >
                    Initial consultations are £50. <br />
                    Patients must be 18+ years of age to use this service.
                  </Typography>
                </CardContent>
              </Card>
              <Card variant="onboarding">
                <CardContent>
                  <FormProvider {...methods}>
                    <Box
                      component="form"
                      onSubmit={methods.handleSubmit(onSubmit)}
                      sx={{ width: "100%" }}
                    >
                      <EmailField
                        fullWidth
                        label="Email"
                        name="email"
                        required
                      />
                      <LazyPasswordWithStrengthField
                        autoComplete="off"
                        fullWidth
                        label="Password"
                        name="password"
                        required
                      />
                      <Stack alignItems="center" spacing={2} width="100%">
                        <PasswordField
                          autoComplete="off"
                          fullWidth
                          label="Confirm Password"
                          name="confirmPassword"
                          required
                        />
                        <Typography variant="subtitle2">
                          By clicking the "Create Account" button you agree to
                          the{" "}
                          <Link href="https://www.curaleafclinic.com/terms/">
                            terms of use
                          </Link>
                          .
                        </Typography>
                        <SubmitButton fullWidth label="Create Account" />
                        <Button
                          color="secondary"
                          fullWidth
                          href="https://app.curaleafclinic.com/sign-in/"
                          variant="contained"
                        >
                          Already have an account? Sign In
                        </Button>
                      </Stack>
                      <Divider
                        sx={{
                          marginBottom: 4,
                          marginTop: 4,
                          alignItems: "center",
                        }}
                        variant="middle"
                      >
                        OR
                      </Divider>
                      <Stack spacing={2}>
                        <NHSSignInButton />
                        <GoogleSignInButton />
                        <MicrosoftSignInButton />
                        <AppleSignInButton />
                      </Stack>
                    </Box>
                  </FormProvider>
                </CardContent>
              </Card>
            </Stack>
          </Box>
        }
        title="Sign Up"
      />
    </>
  );
};

export default SignUp;
