import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface IProps {
  bulletPoints: string[];
  header: string;
  price: number;
  priceFrequency: string;
  showRegistryBulletpoint?: boolean;
}

const PricingInfoColumn = ({
  bulletPoints,
  header,
  price,
  priceFrequency,
  showRegistryBulletpoint,
}: IProps) => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  return (
    <Box sx={{ marginBottom: 2 }}>
      <Typography sx={{ opacity: 0.5 }} variant="subtitle1" gutterBottom>
        {header}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "row", marginBottom: 2 }}>
        <Typography
          variant="landingH5"
          color={theme.palette.primary.main}
          sx={{ fontWeight: 300, paddingRight: "2px" }}
        >
          £
        </Typography>
        <Typography
          variant="landingH4"
          color={
            darkMode
              ? theme.palette.brand.contrastText
              : theme.palette.brand.main
          }
          sx={{ fontSize: { xs: "40px", md: "50px" }, lineHeight: 1 }}
        >
          {Intl.NumberFormat("en-GB", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }).format(price)}
        </Typography>
        <Typography alignSelf="flex-end" sx={{ opacity: 0.5 }}>
          {priceFrequency}
        </Typography>
      </Box>
      <Stack spacing={3}>
        {bulletPoints.map((point, index) => (
          <Stack direction="row" key={index} spacing={1}>
            <CheckCircleOutlinedIcon color="primary" />
            <Typography>{point}</Typography>
          </Stack>
        ))}
        {showRegistryBulletpoint ? (
          <Stack direction="row" spacing={1}>
            <CheckCircleOutlinedIcon color="primary" />
            <Typography>
              Contribute data to the{" "}
              <Link
                href="https://ukmedicalcannabisregistry.com/"
                target="_blank"
              >
                UKMCR
              </Link>
            </Typography>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};
export default PricingInfoColumn;
