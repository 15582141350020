import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface IProps {
  image: string;
  label: string;
}

const BenefitChip = ({ image, label }: IProps) => {
  return (
    <Box
      sx={{
        border: "1px solid white",
        borderRadius: "100px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: "12px 22px",
        backdropFilter: "blur(100px)",
      }}
    >
      <Box
        alt=""
        component="img"
        src={image}
        sx={{ height: "20px", width: "20px", marginRight: "8px" }}
      />
      <Typography sx={{ color: "white" }}>{label}</Typography>
    </Box>
  );
};
export default BenefitChip;
