import { common } from "@mui/material/colors";
import { alpha, PaletteOptions } from "@mui/material/styles";

import { neutral } from "src/theme/colors";
import { getColors } from "src/theme/utils";

export const createPalette = (): PaletteOptions => {
  return {
    ...getColors(),
    action: {
      active: neutral[500],
      disabled: alpha(neutral[100], 0.38),
      disabledBackground: alpha(neutral[100], 0.12),
      focus: alpha(neutral[100], 0.16),
      hover: alpha(neutral[100], 0.04),
      selected: alpha(neutral[100], 0.12),
    },
    background: {
      default: "#222533",
      paper: alpha(common.white, 0.02),
    },
    divider: alpha(common.white, 0.16),
    mode: "dark",
    neutral,
    text: {
      primary: common.white,
      secondary: common.white,
      disabled: "rgba(255, 255, 255, 0.48)",
    },
  };
};
