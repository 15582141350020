import { ToastContext } from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useRef, useState } from "react";
import { useLocation } from "wouter";

import SignatureBox, {
  NoSignatureError,
} from "src/components/Onboarding/SignatureBox";
import {
  usePatientQuery,
  useAssistedReferralQuery,
  useSetSCRPermissionMutation,
  useUpdateAssistedReferralStatusMutation,
  useUpdateOnboardingStatusMutation,
  useUploadSCRSignatureMutation,
} from "src/queries";

interface IProps {
  onBack: () => void;
}

const ConsentCard = ({ onBack }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [_, setLocation] = useLocation();
  const ref = useRef<any>({});
  const { data: assistedReferral } = useAssistedReferralQuery();
  const { data: patient } = usePatientQuery();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const { mutateAsync: updateARStatus } =
    useUpdateAssistedReferralStatusMutation();
  const { mutateAsync: setPermission } = useSetSCRPermissionMutation();
  const { mutateAsync: upload } = useUploadSCRSignatureMutation();

  if (assistedReferral?.status === "Promoted to triage") {
    return null;
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      const blob = await ref.current();
      await upload(blob);
      await setPermission();
      if (patient!.onboardingStatus === "medicalRecordsChoice") {
        await updateOnboardingStatus("sapphireRetrievingScr");
        await updateARStatus(
          "Awaiting SCR - Sapphire getting from GP or from the NCRS",
        );
      }
      setLocation("/onboarding/reviewing/");
    } catch (error) {
      if (error instanceof NoSignatureError) {
        addToast("Please enter a signature", "error");
      } else {
        addToast("Please try again", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="body2">
            Before we can see you, we need to assess whether you are a suitable
            candidate for treatment with cannabis based medication. For this we
            need a detailed summary of your medical records. We can obtain this
            from your GP or the NHS National Care Record Service on your behalf.
            In order to do this we require your signature.
          </Typography>
          <Typography variant="body2">
            Please ensure your signature is complete and accurate or this may
            cause delays
          </Typography>
          <SignatureBox
            defaultSignature={
              assistedReferral !== undefined
                ? `${assistedReferral.firstName} ${assistedReferral.lastName}`
                : undefined
            }
            ref={ref}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <Button fullWidth onClick={onBack} variant="outlined">
          Back
        </Button>
        <LoadingButton
          fullWidth
          loading={loading}
          onClick={onSubmit}
          variant="contained"
        >
          Submit signature
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default ConsentCard;
