import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import { ReactNode } from "react";
import { Helmet } from "react-helmet-async";

import TopBar from "src/components/Onboarding/TopBar";

interface IProps {
  children: ReactNode;
  title: string;
}

const Page = ({ children, title }: IProps) => (
  <>
    <Helmet>
      <title>{title} - Curaleaf Clinic</title>
    </Helmet>
    <TopBar />
    <Toolbar sx={{ marginBottom: 2 }} />
    <Container
      component="main"
      maxWidth="md"
      sx={{ paddingBottom: { xs: 19, lg: 2 }, padding: 2 }}
    >
      {children}
    </Container>
  </>
);

export default Page;
