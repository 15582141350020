import { z } from "zod";

const authUserSchema = z
  .object({
    uid: z.string(),
    email: z.string().email(),
    emailVerified: z.boolean(),
  })
  .transform((value) => ({
    id: value.uid,
    email: value.email,
    emailVerified: value.emailVerified,
  }));

export type AuthUser = z.infer<typeof authUserSchema>;

export const newAuthUser = (data: unknown): AuthUser =>
  authUserSchema.parse(data);
