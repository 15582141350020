import { z } from "zod";

const gpSchema = z
  .object({
    Address1: z.string().nullable().optional(),
    Address2: z.string().nullable().optional(),
    City: z.string().nullable().optional(),
    Email: z.string().nullable().optional(),
    OrganisationName: z.string(),
    Phone: z.string().nullable().optional(),
    Postcode: z.string(),
  })
  .transform((value) => ({
    addressLine1: value.Address1 ?? null,
    addressLine2: value.Address2 ?? null,
    city: value.City ?? null,
    email: value.Email ?? null,
    name: value.OrganisationName,
    phoneNumber: value.Phone ?? null,
    postcode: value.Postcode,
  }));

export type GP = z.infer<typeof gpSchema>;

export const newGP = (data: unknown): GP => gpSchema.parse(data);
