import "src/components/Onboarding/NHSSignInButton/index.css";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";

const NHSSignInButton = () => {
  const [open, setOpen] = useState(false);
  const redirectUri = new URL("/process/sign-in", window.location.origin);
  const url = new URL("https://auth.login.nhs.uk/authorize");
  url.search = new URLSearchParams({
    client_id: "SAPPHIRECLINICS",
    redirect_uri: redirectUri.toString(),
    response_type: "code",
    scope: "openid profile profile_extended email gp_integration_credentials",
    vtr: "['P9.Cp.Cd']",
  }).toString();

  const onClose = () => setOpen(false);

  const handleLoginButtonClick = () => {
    if (localStorage.getItem("nhslogin") === "true") {
      window.open(url, "_self");
      return;
    }
    setOpen(true);
  };

  const handleContinueButtonClick = () => {
    localStorage.setItem("nhslogin", "true");
    window.open(url, "_self");
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} scroll="body">
        <DialogTitle>Sign in with NHS login</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please be aware that you will not be able to use any other sign in
            provider once you have signed in with NHS login.
          </DialogContentText>
          <DialogContentText>
            This service is only available to patients of NHS England.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            component="button"
            onClick={handleContinueButtonClick}
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <button
        className="nhslogin-button nhslogin-button-logo"
        onClick={handleLoginButtonClick}
      >
        <div aria-hidden={true} className="button-logo">
          <svg
            className="nhsuk-logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 16"
            height="27"
            width="67"
          >
            <path
              className="nhsuk-logo__background"
              fill="#fff"
              d="M0 0h40v16H0z"
            >
              {" "}
            </path>
            <path
              className="nhsuk-logo__text"
              fill="#005eb8"
              d="M3.9 1.5h4.4l2.6 9h.1l1.8-9h3.3l-2.8 13H9l-2.7-9h-.1l-1.8 9H1.1M17.3 1.5h3.6l-1 4.9h4L25 1.5h3.5l-2.7 13h-3.5l1.1-5.6h-4.1l-1.2 5.6h-3.4M37.7 4.4c-.7-.3-1.6-.6-2.9-.6-1.4 0-2.5.2-2.5 1.3 0 1.8 5.1 1.2 5.1 5.1 0 3.6-3.3 4.5-6.4 4.5-1.3 0-2.9-.3-4-.7l.8-2.7c.7.4 2.1.7 3.2.7s2.8-.2 2.8-1.5c0-2.1-5.1-1.3-5.1-5 0-3.4 2.9-4.4 5.8-4.4 1.6 0 3.1.2 4 .6"
            >
              {" "}
            </path>
          </svg>
        </div>
        Continue to NHS login
      </button>
    </>
  );
};

export default NHSSignInButton;
