import { useDebounce } from "@curaleaf-international/components";
import Autocomplete from "@mui/material/Autocomplete";
import { useState } from "react";

import Search from "src/components/Onboarding/Search";
import { GP } from "src/models";
import { useGPsQuery } from "src/queries";

interface IProps {
  onSelect: (data: GP) => void;
}

const GPPicker = ({ onSelect }: IProps) => {
  const [rawSearch, setRawSearch] = useState("");
  const search = useDebounce(rawSearch);
  const { data: predictions } = useGPsQuery(search);

  const onChange = (name: string) => {
    setRawSearch(rawSearch);
    const gp = predictions?.find((prediction) => prediction.name === name);
    if (gp !== undefined) {
      onSelect(gp);
    }
  };

  return (
    <>
      <Autocomplete
        autoComplete
        filterOptions={(x) => x}
        filterSelectedOptions
        getOptionLabel={(option) => option}
        noOptionsText="No GPs"
        onChange={(_event: any, newValue: any) => {
          onChange(newValue);
        }}
        options={predictions?.map((prediction) => prediction.name) ?? []}
        onInputChange={(_event, newInputValue) => {
          setRawSearch(newInputValue);
        }}
        renderInput={(params) => (
          <Search
            {...params}
            fullWidth
            label="Search by postcode (England only)"
          />
        )}
        renderOption={(props, option) => <li {...props}>{option}</li>}
      />
    </>
  );
};

export default GPPicker;
