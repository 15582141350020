import Box from "@mui/material/Box";
import { keyframes } from "@mui/system";

import LoaderIcon from "src/components/Onboarding/Loader.svg";

const fadeInOut = keyframes`
  0%,100% { opacity: 0 }
  50% { opacity: 1 }
`;

const Loader = () => (
  <Box
    sx={{
      display: "flex",
      height: "100vh",
      justifyContent: "center",
      width: "100%",
    }}
  >
    <Box
      component="img"
      src={LoaderIcon}
      sx={{ animation: `${fadeInOut} 1.8s linear infinite`, margin: 1 }}
      width="51px"
    />
    <Box
      component="img"
      src={LoaderIcon}
      sx={{
        animation: `${fadeInOut} 1.8s linear infinite`,
        animationDelay: "0.4s",
        margin: 2,
      }}
      width="51px"
    />
    <Box
      component="img"
      src={LoaderIcon}
      sx={{
        animation: `${fadeInOut} 1.8s linear infinite`,
        animationDelay: "0.8s",
        margin: 1,
      }}
      width="51px"
    />
  </Box>
);

export default Loader;
