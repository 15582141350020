import {
  CheckboxField,
  DateField,
  EmailField,
  SelectField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { yupResolver } from "@hookform/resolvers/yup";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { differenceInYears } from "date-fns";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as yup from "yup";

import OccupationSelectField from "src/components/Onboarding/OccupationSelectField";
import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import PartnerPharmacySelectField from "src/components/Onboarding/PartnerPharmacySelectField";
import SplitPage from "src/components/Onboarding/SplitPage";
import {
  usePendingAssistedReferralQuery,
  usePatientQuery,
  useUpdateAboutYouMutation,
  useUpdateOnboardingStatusMutation,
} from "src/queries";

const TITLES = [
  { label: "Mrs", value: "Mrs" },
  { label: "Mr", value: "Mr" },
  { label: "Miss", value: "Miss" },
  { label: "Ms", value: "Ms" },
  { label: "Mx", value: "Mx" },
  { label: "Dr", value: "Dr" },
  { label: "Prof", value: "Prof" },
  { label: "Sir", value: "Sir" },
  { label: "Dame", value: "Dame" },
  { label: "Lord", value: "Lord" },
  { label: "Lady", value: "Lady" },
];

const ATTRIBUTIONS = [
  {
    label: "My doctor or healthcare professional",
    value: "My doctor or healthcare professional",
  },
  {
    label: "Recommended by a patient of Curaleaf Clinic",
    value: "Recommended by a patient of Curaleaf Clinic",
  },
  {
    label: "Billboard",
    value: "Billboard",
  },
  {
    label: "Mind, Body & Spirit Festival",
    value: "Mind, Body & Spirit Festival",
  },
  {
    label: "Recommended by a friend",
    value: "Recommended by a friend",
  },
  { label: "Podcast / Radio", value: "Podcast / Radio" },
  { label: "Google Search", value: "Google Search" },
  { label: "Social Media", value: "Social Media" },
  { label: "Press", value: "Press" },
  { label: "TV", value: "TV" },
  { label: "YouTube", value: "YouTube" },
  { label: "Local pharmacy/leaflet", value: "Local pharmacy/leaflet" },
  { label: "Other (please specify below)", value: "Other" },
];

interface IForm {
  attribution: string;
  carer: string;
  carerEmail: string;
  carerPhonenumber: string;
  carerFirstName: string;
  carerLastName: string;
  carerTitle: string;
  dateOfBirth: Date;
  firstName: string;
  lastName: string;
  veteran: boolean;
  occupation: string;
  other: string;
  pharmacy: string;
  phonenumber: string;
  sex: string;
  title: string;
}

const carerRequired = {
  is: (val: string) => val !== "No",
  then: (schema: yup.Schema) => schema.required(),
};

const validationSchema = yup
  .object({
    attribution: yup.string().required(),
    carer: yup.string().required(),
    carerEmail: yup.string().email().defined().when("carer", carerRequired),
    carerFirstName: yup.string().defined().when("carer", carerRequired),
    carerLastName: yup.string().defined().when("carer", carerRequired),
    carerTitle: yup.string().defined().when("carer", carerRequired),
    carerPhonenumber: yup.string().defined().when("carer", carerRequired),
    dateOfBirth: yup
      .date()
      .required()
      .test("dob", "Should be greater than 18", function (value) {
        return differenceInYears(new Date(), new Date(value)) >= 18;
      }),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    occupation: yup.string().required(),
    veteran: yup.boolean().required(),
    other: yup.string().defined(),
    pharmacy: yup.string().defined(),
    phonenumber: yup.string().required(),
    sex: yup.string().required(),
    title: yup.string().required(),
  })
  .test("pharmacyRequired", "Pharmacy is required", (values) => {
    if (values.attribution === "Local pharmacy/leaflet") {
      return values.pharmacy !== "";
    }
    return true;
  });

const AboutYou = () => {
  const [_, setLocation] = useLocation();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { data: patient } = usePatientQuery();
  const { data: assistedReferral } = usePendingAssistedReferralQuery();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const { mutateAsync: updateAboutYou } = useUpdateAboutYouMutation();

  let defaultCarer = assistedReferral?.carer ?? "";
  if (assistedReferral?.carer === "Yes - Carer") {
    defaultCarer = "Carer";
  } else if (assistedReferral?.carer === "Yes - Guardian") {
    defaultCarer = "Guardian";
  }

  let defaultAttribution = "";
  let defaultOther = "";
  if (assistedReferral?.attribution) {
    if (
      Object.values(ATTRIBUTIONS)
        .map((attribution) => attribution.value)
        .includes(assistedReferral.attribution)
    ) {
      defaultAttribution = assistedReferral.attribution;
    } else {
      defaultAttribution = "Other";
      defaultOther = assistedReferral.attribution;
    }
  }

  const defaultValues = {
    attribution: defaultAttribution,
    carer: defaultCarer,
    carerEmail: assistedReferral?.carerEmail ?? "",
    carerPhonenumber: assistedReferral?.carerPhonenumber ?? "",
    carerFirstName: assistedReferral?.carerFirstName ?? "",
    carerLastName: assistedReferral?.carerLastName ?? "",
    carerTitle: assistedReferral?.carerTitle ?? "",
    dateOfBirth: assistedReferral?.dateOfBirth ?? (null as unknown as Date),
    firstName: assistedReferral?.firstName ?? "",
    lastName: assistedReferral?.lastName ?? "",
    veteran: assistedReferral?.veteran ?? false,
    occupation: assistedReferral?.occupation ?? "",
    other: defaultOther,
    pharmacy:
      assistedReferral?.partnerPharmacy ??
      localStorage.getItem("partnerCode") ??
      "",
    phonenumber: assistedReferral?.phonenumber ?? "",
    sex: assistedReferral?.sex ?? "",
    title: assistedReferral?.title ?? "",
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(
    () => methods.reset(defaultValues),
    [assistedReferral, methods.reset],
  );

  const values = methods.watch();

  useEffect(() => {
    if (values.occupation === "Heavy goods vehicle (HGV) driver") {
      setDialogOpen(true);
    }
  }, [values.occupation]);

  const onSubmit = async (data: IForm) => {
    await updateAboutYou(data);

    if (patient!.onboardingStatus === "genInfo") {
      await updateOnboardingStatus("userAddress");
    }
    setLocation("/onboarding/address/");
  };

  return (
    <>
      <Dialog open={dialogOpen} scroll="body">
        <DialogTitle>HGV Drivers</DialogTitle>
        <DialogContent>
          <Alert severity="warning">
            Please note, we may not be able to prescribe treatment for HGV
            drivers. Contact the patient support team for more information.
          </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDialogOpen(false)} variant="contained">
            Acknowledge & Continue
          </Button>
        </DialogActions>
      </Dialog>
      <SplitPage
        left={<OnboardingStepper />}
        right={
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Stack spacing={2}>
                <Typography variant="h2">About You</Typography>
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      <SelectField
                        fullWidth
                        label="Are you a carer or guardian of the patient?"
                        name="carer"
                        options={[
                          { value: "No" },
                          { label: "Yes - Carer", value: "Carer" },
                          { label: "Yes - Guardian", value: "Guardian" },
                        ]}
                        required
                      />
                      {values.carer !== "No" && values.carer !== "" ? (
                        <>
                          <SelectField
                            fullWidth
                            label={`Title of ${values.carer}`}
                            name="carerTitle"
                            options={TITLES}
                            required
                          />
                          <TextField
                            fullWidth
                            label={`First Name of ${values.carer}`}
                            name="carerFirstName"
                            required
                          />
                          <TextField
                            fullWidth
                            label={`Last Name of ${values.carer}`}
                            name="carerLastName"
                            required
                          />
                          <EmailField
                            fullWidth
                            label={`Email of ${values.carer}`}
                            name="carerEmail"
                            required
                          />
                          <TextField
                            fullWidth
                            label="Phone Number"
                            name="carerPhonenumber"
                            required
                          />
                        </>
                      ) : null}
                      <SelectField
                        fullWidth
                        label="Title"
                        name="title"
                        options={TITLES}
                        required
                      />
                      <TextField
                        fullWidth
                        label="First Name"
                        name="firstName"
                        required
                      />
                      <TextField
                        fullWidth
                        label="Last Name"
                        name="lastName"
                        required
                      />
                      <DateField
                        fullWidth
                        label="Date of Birth"
                        name="dateOfBirth"
                        required
                      />
                      <SelectField
                        fullWidth
                        label="Sex"
                        name="sex"
                        options={[{ value: "Female" }, { value: "Male" }]}
                        required
                      />
                      <TextField
                        fullWidth
                        label="Phone Number"
                        name="phonenumber"
                        required
                      />
                      <CheckboxField
                        fullWidth
                        label="Are you a UK HM Veteran?"
                        helperText="(served for at least one day in His Majesty's Armed Forces), and able to prove?"
                        name="veteran"
                        required
                      />
                      <OccupationSelectField
                        fullWidth
                        label="Occupation"
                        name="occupation"
                        required
                      />
                      <SelectField
                        fullWidth
                        label="Where did you hear about us?"
                        name="attribution"
                        options={ATTRIBUTIONS}
                        required
                      />
                      {values.attribution === "Other" ? (
                        <TextField
                          fullWidth
                          label="Please specify where you heard about us"
                          name="other"
                          required
                        />
                      ) : null}
                      {values.attribution === "Local pharmacy/leaflet" ? (
                        <PartnerPharmacySelectField
                          fullWidth
                          label="Pharmacy branch"
                          name="pharmacy"
                          required
                        />
                      ) : null}
                    </Stack>
                  </CardContent>
                  <CardActions>
                    <SubmitButton
                      disabled={patient === undefined}
                      fullWidth
                      label="Continue"
                    />
                  </CardActions>
                </Card>
              </Stack>
            </form>
          </FormProvider>
        }
        title="About You"
      />
    </>
  );
};

export default AboutYou;
