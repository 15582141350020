import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const FinalSection = () => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  return (
    <Box>
      <Card
        variant="landing"
        sx={{
          backgroundColor: darkMode
            ? theme.palette.neutral[900]
            : theme.palette.brand.main,
          border: "none",
          margin: { xs: "5px", sm: "10px" },
        }}
      >
        <CardContent
          sx={{
            padding: { xs: "30px 15px !important", md: "80px 40px !important" },
          }}
        >
          <Grid container spacing={{ xs: "20px", lg: "50px" }}>
            <Grid
              container
              size={{ xs: 12, lg: 6 }}
              alignItems="center"
              alignContent="center"
              spacing={{ xs: "18px", lg: "30px" }}
            >
              <Typography
                variant="landingH3"
                sx={{ color: theme.palette.brand.contrastText }}
              >
                Feel confident & empowered in managing your condition
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ color: theme.palette.brand.contrastText }}
              >
                Register today to check your eligibility for a consultation with
                a doctor who specialises in your condition.
              </Typography>
              <Stack
                direction={{ xs: "column-reverse", lg: "row" }}
                alignItems={{ xs: "flex-start", lg: "center" }}
                spacing={1}
              >
                <Button
                  href="https://app.curaleafclinic.com/sign-in/"
                  target="_blank"
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.brand.contrastText,
                    color: theme.palette.brand.contrastText,
                    padding: { md: "12px 28px" },
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: 1.25,
                  }}
                >
                  Patient login
                </Button>
                <Button
                  href="https://app.curaleafclinic.com/sign-up/"
                  target="_blank"
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.brand.contrastText,
                    color: darkMode
                      ? theme.palette.neutral[1000]
                      : theme.palette.brand.main,
                    "&:hover": {
                      backgroundColor: alpha(
                        theme.palette.brand.contrastText,
                        0.8,
                      ),
                    },
                    padding: { md: "12px 28px" },
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: 1.25,
                  }}
                >
                  Register with Curaleaf Clinic
                </Button>
              </Stack>
            </Grid>
            <Grid size={{ xs: 12, lg: 6 }}>
              <Card
                variant="landing"
                sx={{
                  height: "100%",
                  width: "100%",
                  border: `1px solid ${alpha(darkMode ? theme.palette.brand.contrastText : theme.palette.brand.main, 0.4)}`,
                }}
              >
                <Box
                  alt={
                    darkMode
                      ? "A woman sitting on the floor, taking notes in a notebook while looking at her phone."
                      : "A woman walking down a street, smiling happily as she looks at her phone, with a bouquet of flowers tucked under her arm."
                  }
                  component="img"
                  src={
                    darkMode
                      ? "/static/media/welcome/WomanWriting.png"
                      : "/static/media/welcome/WomanWithFlowers.png"
                  }
                  sx={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "45%",
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
export default FinalSection;
