import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";

import Blurb from "src/components/LandingPage/Blurb";
import Conditions from "src/components/LandingPage/Conditions";
import FinalSection from "src/components/LandingPage/FinalSection";
import HowDoesItWork from "src/components/LandingPage/HowDoesItWork";
import Opening from "src/components/LandingPage/Opening";
import Pricing from "src/components/LandingPage/Pricing";

const LandingPage = () => {
  return (
    <Container
      maxWidth={false}
      sx={{ width: "100%", maxWidth: "1800px" }}
      disableGutters
    >
      <Stack spacing={{ xs: "80px", sm: "120px" }}>
        <Opening />
        <Blurb />
        <HowDoesItWork />
        <Conditions />
        <Pricing />
        <FinalSection />
      </Stack>
    </Container>
  );
};
export default LandingPage;
