import { z } from "zod";

import { brokenSchema, zFirebaseDateSchema } from "src/models/utils";

const patientSchema = z
  .object({
    curaleafPharmacyPatientId: z.string().nullable().optional(),
    ClinicianStatus: z.string().optional(),
    decisionToPrescribe: z.string().optional(),
    EConsultationApproved: z.boolean().optional(),
    LastEConsultation: zFirebaseDateSchema().nullable().optional(),
    PreventEConsultations: z.boolean().optional(),
    EConsultationsEnabled: z.boolean().optional(),
    FollowUpFrequencyOverride: z.number().positive().nullable().optional(),
    IsTransfer: z.boolean().optional(),
    MoreInfoClinician: z.string().nullable().optional(),
    NextApptDate: brokenSchema(zFirebaseDateSchema()).nullable().optional(),
    NextClinician: z.string().optional(),
    nhsLoginEnabled: z.boolean().nullable().optional(),
    NHSLoginBlocked: z.boolean().optional(),
    NHSRecordsObtained: z.boolean().nullable().optional(),
    OnboardingStatus: z.string(),
    OptInfoComplete: z.boolean().optional(),
    PartnerCode: z.string().optional(),
    SharedCare: z.boolean().optional(),
    Status: z.string().optional(),
    WantsLargerQuantity: z.boolean().optional(),
    WantsPastilles: z.boolean().optional(),
  })
  .transform((value) => ({
    curaleafPatientId: value.curaleafPharmacyPatientId ?? null,
    assignedClinician: value.NextClinician ?? null,
    clinicianStatus: value.ClinicianStatus ?? null,
    decisionToPrescribe: value.decisionToPrescribe ?? null,
    eConsultationApproved: value.EConsultationApproved ?? false,
    isInSharedCared: value.SharedCare ?? false,
    isTransferPatient: value.IsTransfer ?? false,
    lastEConsultation: value.LastEConsultation ?? null,
    preventEConsultations: value.PreventEConsultations ?? false,
    eConsultationsEnabled: value.EConsultationsEnabled ?? false,
    followUpFrequencyOverride: value.FollowUpFrequencyOverride ?? null,
    moreInfo: value.MoreInfoClinician ?? null,
    nextAppointment: value.NextApptDate ?? null,
    nhsLoginBlocked: value.NHSLoginBlocked ?? false,
    nhsRecordsObtained: value.NHSRecordsObtained ?? false,
    onboardingStatus:
      value.OnboardingStatus === "appointmentBooking"
        ? "completed"
        : value.OnboardingStatus,
    optInfoComplete: value.OptInfoComplete ?? false,
    partnerCode: value.PartnerCode ?? null,
    signedInWithNhs: value.nhsLoginEnabled ?? false,
    status: value.Status ?? null,
    wantsLargerQuantities: value.WantsLargerQuantity ?? false,
    wantsPastilles: value.WantsPastilles ?? false,
  }));

export type Patient = z.infer<typeof patientSchema>;

export const newPatient = (data: unknown): Patient => patientSchema.parse(data);
