import {
  createTheme as createMuiTheme,
  Direction,
  PaletteMode,
  responsiveFontSizes,
  Theme,
} from "@mui/material/styles";

import { createOptions as createBaseOptions } from "src/theme/base/create-options";
import { createOptions as createDarkOptions } from "src/theme/dark/create-options";
import { createOptions as createLightOptions } from "src/theme/light/create-options";

declare module "@mui/material/styles" {
  export interface NeutralColors {
    20: string;
    50: string;
    100: string;
    150: string;
    200: string;
    300: string;
    400: string;
    500: string;
    600: string;
    700: string;
    800: string;
    900: string;
    1000: string;
  }

  interface Palette {
    brand: {
      main: string;
      contrastText: string;
    };
    neutral: NeutralColors;
  }

  interface PaletteOptions {
    brand?: {
      main: string;
      contrastText: string;
    };
    neutral?: NeutralColors;
  }

  interface TypeBackground {
    paper: string;
    default: string;
  }
}

interface IFonts {
  base: string;
  heading: string;
}

interface ThemeConfig {
  direction?: Direction;
  paletteMode?: PaletteMode;
  responsiveFontSizes?: boolean;
  fonts: IFonts;
}

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    // Base options available for both dark and light palette modes
    createBaseOptions({
      baseFont: config.fonts.base,
      direction: config.direction,
      headingFont: config.fonts.heading,
    }),
    // Options based on selected palette mode, color preset and contrast
    config.paletteMode === "dark" ? createDarkOptions() : createLightOptions(),
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
