import { AutocompleteField } from "@curaleaf-international/components";
import Skeleton from "@mui/material/Skeleton";
import { SxProps } from "@mui/system";

import { usePartnerPharmaciesQuery } from "src/queries";

interface IProps {
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  label: string;
  name: string;
  required?: boolean;
  sx?: SxProps;
}

const PartnerPharmacySelectField = (props: IProps) => {
  const { data: partnerPharmacies } = usePartnerPharmaciesQuery();

  if (partnerPharmacies === undefined) {
    return <Skeleton height="60px" width="100%" />;
  } else {
    return (
      <AutocompleteField
        {...props}
        options={partnerPharmacies.map((pharmacy) => ({
          label: `${pharmacy.name}, ${pharmacy.addressLine1}, ${pharmacy.addressLine2}, ${pharmacy.postcode}`,
          value: pharmacy.code,
        }))}
      />
    );
  }
};

export default PartnerPharmacySelectField;
