import { createComponents } from "src/theme/dark/create-components";
import { createPalette } from "src/theme/dark/create-palette";
import { createShadows } from "src/theme/dark/create-shadows";

export const createOptions = () => {
  const palette = createPalette();
  const components = createComponents({ palette });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows,
  };
};
