import { z } from "zod";

const assistedReferralSchema = z
  .object({
    FirstName: z.string(),
    LastName: z.string(),
    MoreInfoAR: z.string().nullable().default(null),
    NCRSPermission: z.coerce.boolean(),
    SOCPermission: z.boolean(),
    Status: z.string(),
  })
  .transform((value) => ({
    firstName: value.FirstName,
    lastName: value.LastName,
    moreInfo: value.MoreInfoAR,
    ncrsPermission: value.NCRSPermission,
    socPermission: value.SOCPermission,
    status: value.Status,
  }));

export type AssistedReferral = z.infer<typeof assistedReferralSchema>;

export const newAssistedReferral = (data: unknown): AssistedReferral =>
  assistedReferralSchema.parse(data);
