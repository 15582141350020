interface IProps {
  color?: string;
  height: number;
}

const fill = {
  st0: "#084C5F",
  st1: "#0094CA",
};

const Logo = ({ height, color = "currentColor" }: IProps) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 313.29 83.49"
    xmlSpace="preserve"
    height={height}
  >
    <path
      fill={color}
      d="M141.5,64.39c-0.01,7.66,6.19,13.89,13.85,13.92c4.2,0.03,8.21-1.79,10.93-5c0.51-0.65,1.28-1.04,2.11-1.06
  c1.33-0.05,2.45,0.98,2.51,2.31c0,0.05,0,0.1,0,0.15c-0.01,0.73-0.3,1.44-0.81,1.96c-3.74,4.19-9.13,6.52-14.74,6.39
  c-11.26,0-19.6-8.6-19.6-20.13s8.34-20.14,19.11-20.14c10.93,0,18.14,7.45,18.14,18.75c0,1.88-0.97,2.87-3,2.87L141.5,64.39z
   M167.42,59.97c-0.33-7.21-4.7-12.53-12.55-12.53c-7.01,0.09-12.79,5.53-13.29,12.53H167.42z"
    />
    <path
      fill={color}
      d="M135.08,78.39c-1,0.08-1.95-0.46-2.4-1.36c-0.38-0.89-0.56-1.86-0.53-2.83V31.8c0.01-0.66-0.26-1.29-0.73-1.75
  c-1.13-0.97-2.81-0.97-3.94,0c-0.48,0.46-0.74,1.09-0.74,1.75v42.79c-0.18,2.28,0.39,4.55,1.6,6.49c1.21,1.41,3.03,2.15,4.89,1.98
  c0.88,0.08,1.75-0.17,2.44-0.7c0.5-0.51,0.77-1.21,0.72-1.93c0.01-0.54-0.12-1.08-0.38-1.56C135.83,78.53,135.46,78.34,135.08,78.39
  "
    />
    <path
      fill={color}
      d="M238.63,30.69c-0.39-0.51-0.95-0.87-1.59-0.99c-0.45-0.12-0.91-0.2-1.37-0.24c-0.83-0.08-1.67-0.12-2.51-0.11
  c-3.86-0.23-7.61,1.34-10.15,4.26c-2.55,2.85-3.84,6.92-3.84,12.18v34.74c-0.02,0.68,0.25,1.34,0.74,1.81
  c0.52,0.49,1.21,0.76,1.92,0.73c0.72,0.04,1.43-0.22,1.95-0.72c0.48-0.48,0.73-1.14,0.71-1.82V49.49h8.49
  c0.63,0.02,1.25-0.23,1.69-0.68c0.46-0.47,0.7-1.12,0.67-1.78c0.02-0.62-0.22-1.22-0.66-1.66c-0.45-0.44-1.06-0.67-1.69-0.65h-8.45
  l0.05-0.72c0-2.56,0.82-5.04,2.35-7.09c1.54-1.88,3.91-2.86,6.32-2.63c0.69-0.01,1.38,0.03,2.07,0.12c0.49,0.07,0.99,0.11,1.49,0.11
  c0.6,0.02,1.19-0.18,1.66-0.57c0.47-0.48,0.7-1.15,0.63-1.82c-0.01-0.51-0.17-1.01-0.46-1.43"
    />
    <path
      fill={color}
      d="M31.51,73.72c1.29-0.05,2.38,0.95,2.43,2.23c0,0.05,0,0.09,0,0.14c-0.01,0.81-0.37,1.59-0.97,2.13
  c-3.41,3.27-8,5.01-12.71,4.83C8.02,83.06,0,74.46,0,62.92s8.02-20.13,20.25-20.13c4.5-0.03,8.85,1.61,12.23,4.58
  c0.65,0.55,1.03,1.36,1.06,2.21c0,1.36-1.1,2.46-2.46,2.46h-0.05c-0.73,0-1.3-0.16-2.76-1.55c-2.16-1.99-5-3.07-7.94-3.03
  c-8.83,0-14.5,6.72-14.5,15.47c0,8.68,5.59,15.39,14.42,15.39c3.38,0.11,6.65-1.16,9.07-3.52c0.56-0.63,1.34-1.01,2.19-1.06"
    />
    <path
      fill={color}
      d="M64.74,42.78c-0.69-0.01-1.37,0.22-1.91,0.67c-0.57,0.47-0.89,1.18-0.88,1.92v22.23
  c0.01,1.43-0.21,2.85-0.65,4.2c-0.39,1.21-1.04,2.31-1.9,3.25c-0.87,0.92-1.91,1.65-3.08,2.15c-2.69,1.05-5.67,1.05-8.36,0
  c-1.16-0.5-2.21-1.23-3.08-2.15c-0.86-0.93-1.51-2.04-1.9-3.25c-0.44-1.36-0.66-2.77-0.65-4.2V45.36c0.02-0.74-0.3-1.45-0.88-1.92
  c-0.54-0.44-1.21-0.68-1.91-0.67c-0.69-0.02-1.36,0.23-1.86,0.7c-0.52,0.49-0.8,1.17-0.77,1.88v22.08c-0.02,2.1,0.3,4.19,0.96,6.18
  c0.61,1.84,1.6,3.53,2.9,4.97c1.33,1.44,2.97,2.57,4.79,3.3c2.09,0.82,4.33,1.21,6.57,1.17c2.22,0.04,4.43-0.36,6.49-1.17
  c1.83-0.73,3.46-1.86,4.8-3.31c1.3-1.44,2.3-3.12,2.94-4.96c0.68-1.99,1.02-4.08,1-6.18V45.36c0.03-0.71-0.25-1.4-0.77-1.89
  C66.09,43.01,65.42,42.76,64.74,42.78"
    />
    <path
      fill={color}
      d="M124.42,78.88c-0.18-0.34-0.56-0.53-0.94-0.48c-1,0.08-1.94-0.46-2.39-1.36c-0.38-0.89-0.56-1.85-0.53-2.82
  V45.25c0.01-0.66-0.26-1.29-0.73-1.75c-1.13-0.97-2.8-0.97-3.94,0c-0.47,0.46-0.74,1.09-0.73,1.75v5.21
  c-1.2-1.88-2.72-3.54-4.49-4.89c-2.69-1.93-5.95-2.91-9.26-2.79c-2.49-0.02-4.96,0.5-7.22,1.54c-2.18,1.01-4.15,2.44-5.77,4.22
  c-1.68,1.87-2.99,4.04-3.85,6.41c-0.94,2.57-1.41,5.28-1.39,8.01c-0.02,2.72,0.44,5.42,1.35,7.97c0.84,2.35,2.14,4.52,3.82,6.37
  c1.61,1.77,3.56,3.2,5.73,4.22c2.25,1.04,4.71,1.56,7.19,1.54c3.31,0.07,6.55-0.95,9.22-2.91c1.85-1.38,3.44-3.08,4.69-5.02v-0.28
  c-0.13,2.19,0.42,4.37,1.59,6.24c1.21,1.41,3.03,2.15,4.88,1.97c0.87,0.08,1.74-0.17,2.44-0.7c0.5-0.51,0.76-1.21,0.72-1.92
  C124.81,79.89,124.68,79.35,124.42,78.88 M101.86,77.52c-8.17,0-13.3-6.56-13.3-14.65c0-8.09,5.47-14.65,13.3-14.65
  c7.08,0,13.3,6.19,13.3,14.65C115.16,71.86,108.95,77.52,101.86,77.52"
    />
    <path
      fill={color}
      d="M102.89,37.87c0.76,0.05,1.51-0.24,2.02-0.81c0.44-0.53,0.67-1.2,0.64-1.89c0-0.74-0.54-3.01-4.15-3.01
  c-5.69-0.09-11.26,1.62-15.92,4.9c-5.86,3.92-7.8,10.32-8.31,11.11v-2.86c0.1-1.31-0.87-2.45-2.18-2.55
  c-0.16-0.01-0.33-0.01-0.49,0.01c-0.72-0.04-1.42,0.23-1.95,0.72c-0.48,0.48-0.73,1.13-0.71,1.81v35.13
  c-0.03,0.74,0.28,1.44,0.83,1.93c0.53,0.46,1.21,0.7,1.91,0.69c0.7,0.02,1.38-0.23,1.89-0.7c0.52-0.5,0.8-1.19,0.77-1.91V67.87
  c-0.17-3.32-0.01-6.65,0.46-9.95c0.62-3.26,1.79-6.38,3.45-9.25c1.51-2.69,3.6-5.01,6.11-6.8c0.46-0.32,0.93-0.62,1.42-0.89
  C93.08,38.75,97.97,37.68,102.89,37.87"
    />
    <path
      fill={color}
      d="M215.96,78.88c-0.18-0.34-0.55-0.53-0.94-0.48c-1,0.08-1.94-0.46-2.39-1.36c-0.38-0.89-0.56-1.85-0.53-2.82
  V45.25c0.01-0.66-0.26-1.29-0.73-1.75c-1.13-0.97-2.8-0.97-3.94,0c-0.48,0.46-0.74,1.09-0.73,1.75v5.21
  c-1.2-1.88-2.72-3.54-4.49-4.89c-2.7-1.93-5.95-2.91-9.26-2.79c-2.49-0.02-4.96,0.5-7.22,1.54c-2.19,1.01-4.15,2.44-5.78,4.22
  c-1.68,1.87-2.99,4.04-3.84,6.41c-0.94,2.57-1.41,5.28-1.39,8.01c-0.02,2.72,0.44,5.42,1.35,7.97c0.84,2.35,2.14,4.52,3.82,6.37
  c1.61,1.77,3.56,3.2,5.73,4.22c2.25,1.04,4.71,1.56,7.19,1.54c3.31,0.07,6.55-0.95,9.22-2.91c1.85-1.38,3.44-3.09,4.69-5.03v-0.28
  c-0.13,2.19,0.42,4.37,1.59,6.24c1.21,1.41,3.03,2.15,4.88,1.97c0.87,0.08,1.74-0.17,2.44-0.7c0.5-0.51,0.76-1.21,0.72-1.92
  c0.01-0.54-0.12-1.08-0.38-1.55 M193.4,77.52c-8.17,0-13.3-6.56-13.3-14.65c0-8.09,5.47-14.65,13.3-14.65
  c7.08,0,13.3,6.19,13.3,14.65C206.7,71.86,200.48,77.52,193.4,77.52"
    />
    <path
      fill={fill.st1}
      d="M140.34,0.08c-0.51-0.49-4.31,1.36-8.27,1.79c-3.96,0.42-9.24,0.67-14.79,6.18c-7.4,7.35-3.6,18.05-3.31,17.29
  c0.3-0.76-2.14-8.24,4.71-15.13c6.85-6.89,17.04-5.9,17.04-5.9s-0.77,11.6-6.57,17.24c-2.7,2.87-6.35,4.66-10.28,5.01l0.27-1.29
  c0.17-1.13,0.42-2.25,0.74-3.35c0.33-1.04,0.81-2.03,1.42-2.94c0.62-0.92,1.32-1.78,2.09-2.58c0.19-0.2,0.38-0.42,0.58-0.61
  c0.04-0.04,0.48-0.44,0.34-0.51c-0.1-0.05-0.26,0-0.35,0.03c-0.24,0.08-0.47,0.18-0.7,0.31c-0.33,0.18-0.66,0.39-0.96,0.61
  c-0.4,0.3-0.79,0.63-1.15,0.98c-0.45,0.43-0.87,0.9-1.26,1.38c-0.47,0.59-0.9,1.2-1.29,1.84c-0.46,0.75-0.88,1.53-1.25,2.33
  c-0.54,1.16-0.92,2.38-1.43,3.54c-0.52,1.18-1.14,2.31-1.84,3.39c-0.49,0.88-1.32,1.51-2.29,1.74c-0.38,0.09-0.76,0.13-1.15,0.15
  c-0.78,0.01-1.54,0.23-2.21,0.64c-1.19,0.88-1.53,2.51-0.77,3.78c0.75,1.2,2.77,1.21,3.96,0.8c1.36-0.65,2.52-1.65,3.36-2.9
  l0.05-0.06l0.12-0.15l0.18-0.25l0.23-0.32l0.27-0.37c0.1-0.14,0.2-0.27,0.3-0.41l0.31-0.43l0.31-0.43c0.1-0.14,0.2-0.28,0.31-0.42
  l0.28-0.38l0.25-0.34l0.2-0.27l0.11-0.15c0.03-0.05,0.06-0.09,0.1-0.13c0.1-0.07,0.21-0.11,0.34-0.12c0.1-0.01,0.21-0.02,0.31-0.02
  l0.12-0.01c0.04,0,0.09-0.01,0.14-0.01c1.37-0.18,2.72-0.47,4.04-0.87c1.94-0.56,3.78-1.42,5.47-2.54c0.65-0.44,1.26-0.94,1.81-1.5
  c6.68-6.63,7.39-14.43,8.33-18.11C139.47,2.95,140.85,0.58,140.34,0.08"
    />
    <path
      fill={fill.st1}
      d="M251.65,77.97l-0.57-0.35c-0.63-0.39-1.33-0.16-1.92,0.62c-1.11,1.58-2.39,2.29-4.15,2.29
  c-3.16,0-4.9-2.39-4.9-6.74c0-4.24,1.89-6.88,4.93-6.88c1.7,0,2.85,0.65,3.72,2.09c0.5,0.86,1.15,1.18,1.83,0.9l0.54-0.22
  c0.51-0.22,0.75-0.62,0.75-1.24c0-0.25-0.08-0.46-0.16-0.65c-1.31-2.56-3.58-3.85-6.74-3.85c-5.15,0-8.22,3.68-8.22,9.84
  c0,5.98,3.11,9.7,8.11,9.7c3.08,0,5.38-1.21,7.05-3.69c0.22-0.32,0.29-0.73,0.21-1.11C252.06,78.38,251.89,78.12,251.65,77.97"
    />
    <path
      fill={fill.st1}
      d="M257.6,57.31h-0.32c-0.85,0-1.54,0.69-1.54,1.53v22.69c0,0.85,0.69,1.54,1.54,1.54h0.32
  c0.85,0,1.54-0.69,1.54-1.54V58.84C259.13,57.99,258.44,57.31,257.6,57.31"
    />
    <path
      fill={fill.st1}
      d="M265.69,64.37h-0.32c-0.85,0-1.54,0.69-1.54,1.54v15.62c0,0.85,0.69,1.53,1.54,1.53h0.32
  c0.85,0,1.54-0.69,1.54-1.53V65.91C267.23,65.06,266.54,64.37,265.69,64.37"
    />
    <path
      fill={fill.st1}
      d="M265.87,57.2h-0.64c-0.85,0-1.54,0.69-1.54,1.53v0.96c0,0.85,0.69,1.53,1.54,1.53h0.64
  c0.85,0,1.53-0.69,1.53-1.53v-0.96C267.4,57.89,266.71,57.2,265.87,57.2"
    />
    <path
      fill={fill.st1}
      d="M281.21,63.95c-2.18,0-4.06,0.84-6.03,2.71v-0.75c0-0.9-0.56-1.54-1.36-1.54h-0.53c-0.8,0-1.36,0.63-1.36,1.54
  v15.62c0,0.85,0.69,1.54,1.54,1.54h0.32c0.85,0,1.54-0.69,1.54-1.54V69.7c1.88-1.92,3.33-2.71,5-2.71c1.99,0,2.85,1.01,2.85,3.38
  v11.17c0,0.85,0.69,1.54,1.53,1.54h0.32c0.85,0,1.54-0.69,1.54-1.54V70.4C286.56,66,284.86,63.95,281.21,63.95"
    />
    <path
      fill={fill.st1}
      d="M292.91,64.37h-0.32c-0.85,0-1.54,0.69-1.54,1.54v15.62c0,0.85,0.69,1.53,1.54,1.53h0.32
  c0.85,0,1.54-0.69,1.54-1.53V65.91C294.45,65.06,293.76,64.37,292.91,64.37"
    />
    <path
      fill={fill.st1}
      d="M293.09,57.2h-0.64c-0.85,0-1.54,0.69-1.54,1.53v0.96c0,0.85,0.69,1.53,1.54,1.53h0.64
  c0.85,0,1.53-0.69,1.53-1.53v-0.96C294.62,57.89,293.94,57.2,293.09,57.2"
    />
    <path
      fill={fill.st1}
      d="M313.25,78.69c-0.07-0.31-0.24-0.57-0.48-0.72l-0.57-0.35c-0.63-0.39-1.33-0.16-1.92,0.62
  c-1.11,1.58-2.39,2.28-4.15,2.28c-3.16,0-4.9-2.39-4.9-6.74c0-4.24,1.89-6.88,4.93-6.88c1.7,0,2.85,0.65,3.72,2.1
  c0.5,0.86,1.15,1.18,1.83,0.9l0.54-0.21c0.51-0.22,0.75-0.62,0.75-1.24c0-0.25-0.08-0.46-0.16-0.65c-1.31-2.56-3.58-3.85-6.74-3.85
  c-5.14,0-8.22,3.68-8.22,9.84c0,5.98,3.11,9.7,8.11,9.7c3.08,0,5.38-1.21,7.05-3.69C313.26,79.47,313.34,79.07,313.25,78.69"
    />
  </svg>
);

export default Logo;
