import { ToastContext } from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { useLocation } from "wouter";

import Dropzone from "src/components/Onboarding/Dropzone";
import YoutubeDialog from "src/pages/MedicalRecords/YoutubeDialog";
import {
  usePatientQuery,
  useUpdateAssistedReferralStatusMutation,
  useUpdateOnboardingStatusMutation,
  useUploadARDocumentsMutation,
} from "src/queries";

interface IProps {
  onBack: () => void;
}

const UploadCard = ({ onBack }: IProps) => {
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [helpLink, setHelpLink] = useState<string | null>(null);
  const [_, setLocation] = useLocation();
  const { data: patient } = usePatientQuery();
  const { mutateAsync: upload } = useUploadARDocumentsMutation();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const { mutateAsync: updateARStatus } =
    useUpdateAssistedReferralStatusMutation();

  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: File[]) => {
    setFiles((files) => [...files, ...newFiles]);
  };

  const onUpload = async () => {
    setLoading(true);
    try {
      await upload(files);

      if (patient!.onboardingStatus === "medicalRecordsChoice") {
        await updateOnboardingStatus("reviewingMedicalInfo");
        await updateARStatus("Medical information awaiting review");
      }
      addToast("Files uploaded successfully", "success");
      setLocation("/onboarding/reviewing/");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <YoutubeDialog
        open={helpLink !== null}
        onClose={() => setHelpLink(null)}
        title="Help fetching your records"
        video={helpLink ?? ""}
      />
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="body1">
              Please upload your GP Summary of Care records and any other clinic
              letters / records relevant to your condition and treatment.
            </Typography>
            <ButtonGroup fullWidth size="medium" aria-label="Help options">
              <Button
                color="secondary"
                onClick={() =>
                  setHelpLink(
                    "https://www.youtube-nocookie.com/embed/cL0cf6SmaD0",
                  )
                }
                variant="outlined"
              >
                How to get your medical records
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  setHelpLink(
                    "https://www.youtube-nocookie.com/embed/Q0SCcLtw8JA",
                  )
                }
                variant="outlined"
              >
                How to setup your NHS app
              </Button>
            </ButtonGroup>
            <Dropzone multiple={true} onDrop={onDrop} />
            <List>
              {files.map((file, index) => (
                <ListItem
                  key={`${file.name}-${index}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        setFiles((values) =>
                          values.filter((value) => value !== file),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
          </Stack>
        </CardContent>
        <CardActions>
          <Button fullWidth onClick={onBack} variant="outlined">
            Back
          </Button>
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={onUpload}
            variant="contained"
            disabled={files.length === 0}
          >
            Upload files
          </LoadingButton>
        </CardActions>
      </Card>
    </>
  );
};

export default UploadCard;
