import { useQueryClient } from "@tanstack/react-query";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { db, functions } from "src/firebase";
import { Patient, newPatient } from "src/models/patient";
import { useMutation, useQuery } from "src/query";

export const usePatientQuery = () => {
  const { user } = useContext(AuthContext);

  return useQuery<Patient>({
    queryKey: ["patient"],
    queryFn: async () => {
      const snapshot = await getDoc(doc(db, "MainPAppData", user!.id));
      if (!snapshot.exists()) {
        throw new Error();
      }
      return newPatient(snapshot.data());
    },
    enabled: user !== undefined && user !== null,
  });
};

export const useUpdateOnboardingStatusMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (status: string) => {
      await updateDoc(doc(db, `MainPAppData/${user!.id}`), {
        OnboardingStatus: status,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["patient"] });
    },
  });
};

interface IDeclineData {
  reason: string;
  status: string;
}
const declineTreatment = httpsCallable(
  functions,
  "AppOnCall-handlePatientDeclineToProceed",
);

export const useDeclineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (data: IDeclineData) => {
      await declineTreatment(data);
    },
    onSuccess: () => {
      setTimeout(async () => {
        await queryClient.invalidateQueries({ queryKey: ["patient"] });
        await queryClient.invalidateQueries({ queryKey: ["assistedReferral"] });
      }, 500);
    },
  });
};
