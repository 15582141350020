import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import CareQualityCommission from "src/components/Onboarding/CareQualityCommision.svg";

const ProcessSidebar = () => (
  <Stack spacing={2} alignItems="center">
    <Typography variant="h2">Our Process</Typography>
    <List disablePadding>
      <ListItem>
        <ListItemAvatar>
          <Avatar>1</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Create an Account"
          secondary="Complete a short set of questions and share your medical records. You can send us the medical records directly via NHS login or give us permission to get them on your behalf."
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>2</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Book an Appointment"
          secondary="Our clinicians will review your application and, if suitable, you can book a virtual appointment. Join the Curaleaf Access Scheme and your first follow up appointment is free."
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>3</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="MDT Approval"
          secondary="After your consultation, our Multi-Disciplinary Team (MDT) of doctors and pharmacists will assess whether medical cannabis is appropriate for you."
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>4</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Prescriptions"
          secondary="If approved, your prescription will be sent to your pharmacy, who will contact you to arrange payment and delivery, straight to your door."
        />
      </ListItem>
      <ListItem>
        <ListItemAvatar>
          <Avatar>5</Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Ongoing Care"
          secondary="Patient coordinators are on-hand to answer your questions and admin is made simple with the Curaleaf Clinic Web App, where you can book appointments, request repeat prescriptions and track your health over time."
        />
      </ListItem>
    </List>
    <Box padding={2}>
      <img src={CareQualityCommission} alt="CQC-logo" />
    </Box>
  </Stack>
);

export default ProcessSidebar;
