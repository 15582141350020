import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import { Link as WLink } from "wouter";

import { usePatientQuery } from "src/queries/patient";

const OnboardingStepper = () => {
  const { data: patient } = usePatientQuery();

  let step = 0;
  switch (patient?.onboardingStatus) {
    case "pending":
    case "genInfo":
      step = 0;
      break;
    case "userAddress":
      step = 1;
      break;
    case "healthInfo":
      step = 2;
      break;
    case "gpInfo":
      step = 3;
      break;
    case "medicalRecordsChoice":
      step = 4;
      break;
    case "patientRetrievingScr":
    case "reviewingMedicalInfo":
    case "sapphireRetrievingScr":
    case "moreInformationRequiredClinician":
    case "moreInformationRequired":
      step = 5;
      break;
    default:
      step = 0;
  }

  return (
    <Stack spacing={2}>
      <Stepper activeStep={step} orientation="vertical">
        <Step completed={step > 0}>
          <StepButton
            component={WLink}
            to="/onboarding/about-you/"
            disabled={step < 0 || step === 5}
          >
            General information
          </StepButton>
        </Step>
        <Step completed={step > 1}>
          <StepButton
            component={WLink}
            to="/onboarding/address/"
            disabled={step < 1 || step === 5}
          >
            Address
          </StepButton>
        </Step>
        <Step completed={step > 2}>
          <StepButton
            component={WLink}
            to="/onboarding/health/"
            disabled={step < 2 || step === 5}
          >
            About your health
          </StepButton>
        </Step>
        <Step completed={step > 3}>
          <StepButton
            component={WLink}
            to="/onboarding/gp/"
            disabled={step < 3 || step === 5}
          >
            About your GP
          </StepButton>
        </Step>
        <Step completed={step > 4}>
          <StepButton
            component={WLink}
            to="/onboarding/medical-records/"
            disabled={step < 4 || step === 5}
          >
            Your medical records
          </StepButton>
        </Step>
        <Step completed={step > 5}>
          <StepLabel>Reviewing your information</StepLabel>
        </Step>
      </Stepper>
      {patient?.signedInWithNhs ? (
        <Typography variant="body2">
          <Link href="https://settings.login.nhs.uk/" target="_blank">
            Click here to manage your NHS login settings
          </Link>
        </Typography>
      ) : null}
    </Stack>
  );
};

export default OnboardingStepper;
