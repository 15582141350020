import {
  darkGreyBlue,
  electricBlue,
  fadedRed,
  irishGreen,
  papayaOrange,
} from "src/theme/colors";

export const getColors = () => {
  return {
    brand: darkGreyBlue,
    primary: electricBlue,
    error: fadedRed,
    info: electricBlue,
    success: irishGreen,
    warning: papayaOrange,
  };
};
