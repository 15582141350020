import { Breakpoints } from "@mui/material/styles";
import type { TypographyOptions } from "@mui/material/styles/createTypography";

export const createTypography = (
  baseFont: string,
  headingFont: string,
  breakpoints: Breakpoints,
): TypographyOptions => {
  return {
    fontFamily: `"${baseFont}", Arial, sans-serif`,
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.375,
    },
    button: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.375,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: 1.66,
    },
    subtitle1: {
      fontSize: "1.25rem",
      fontWeight: 400,
      lineHeight: 1.4,
      [breakpoints.down("sm")]: {
        fontSize: "1rem",
      },
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    h1: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontSize: "1.5rem",
      fontWeight: 500,
      lineHeight: 1.875,
      letterSpacing: "-0.031em",
    },
    h2: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontSize: "1.25rem",
      fontWeight: 500,
      lineHeight: 1.625,
      letterSpacing: "-0.031rem",
    },
    h3: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontSize: "1.125rem",
      fontWeight: 500,
      lineHeight: 1.5,
      letterSpacing: "-0.016rem",
    },
    h4: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontSize: "1rem",
      fontWeight: 500,
      lineHeight: 1.375,
      letterSpacing: "-0.016rem",
    },
    h5: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: "0rem",
    },
    h6: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: 1.125,
      letterSpacing: "0rem",
    },
    landingH1: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontWeight: 500,
      fontSize: "12.5rem",
      lineHeight: 1,
      letterSpacing: "-0.5rem",
      [breakpoints.down("sm")]: {
        fontSize: "4.626rem",
        letterSpacing: "-0.2rem",
      },
    },
    landingH2: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontWeight: 900,
      fontSize: "9.25rem",
      lineHeight: 0.89,
      letterSpacing: "-0.125rem",
      [breakpoints.down("md")]: {
        fontSize: "6.9rem",
        lineHeight: 1,
        letterSpacing: "-0.031rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "4.625rem",
        lineHeight: 1,
        letterSpacing: "-0.031rem",
      },
      [breakpoints.down(400)]: {
        fontSize: "3.8rem",
        lineHeight: 1,
        letterSpacing: "-0.031rem",
      },
    },
    landingH3: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontWeight: 500,
      fontSize: "6.25rem",
      lineHeight: 1,
      letterSpacing: "-0.313rem",
      [breakpoints.down("md")]: {
        fontSize: "2.625rem",
        lineHeight: 1.1,
        letterSpacing: "-0.125rem",
      },
    },
    landingH4: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontWeight: 500,
      fontSize: "2.375rem",
      lineHeight: 1.4,
      [breakpoints.down("sm")]: {
        fontSize: "1.625rem",
      },
    },
    landingH5: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontWeight: 500,
      fontSize: "1.625rem",
      lineHeight: 1.23,
      [breakpoints.down("md")]: {
        fontSize: "1.375rem",
        lineHeight: 1.27,
      },
    },
    landingH6: {
      fontFamily: `"${headingFont}", Georgia, sans-serif`,
      fontWeight: 700,
      fontSize: "1.125rem",
      lineHeight: 1.2,
    },
  };
};
