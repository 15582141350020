import { FirebaseError } from "firebase/app";
import { httpsCallable } from "firebase/functions";

import { functions } from "src/firebase";
import { GP, newGP } from "src/models";
import { useQuery } from "src/query";

const getGpInfo = httpsCallable(functions, "AppOnCall-searchForGPInfo");

export const useGPsQuery = (postcode: string) => {
  return useQuery<GP[]>({
    queryKey: ["gps", { postcode }],
    queryFn: async () => {
      try {
        const gpInfo = await getGpInfo({ postcode });
        return (gpInfo as any).data.results.map((data: unknown) => newGP(data));
      } catch (error) {
        if (error instanceof FirebaseError) {
          return [];
        } else {
          throw error;
        }
      }
    },
    enabled: postcode !== "",
  });
};
