import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

interface IProps {
  onClose: () => void;
  open: boolean;
  title: string;
  video: string;
}

const YoutubeDialog = ({ onClose, open, title, video }: IProps) => (
  <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose} scroll="body">
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <iframe
        width="100%"
        height="315"
        src={video}
        title={title}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </DialogContent>
  </Dialog>
);

export default YoutubeDialog;
