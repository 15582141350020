import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid2";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import HowDoesItWorkCard from "src/components/LandingPage/HowDoesItWorkCard";
import HowDoesItWorkCarousel from "src/components/LandingPage/HowDoesItWorkCarousel";

const HowDoesItWork = () => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";
  return (
    <Box>
      <Grid
        sx={{
          margin: { xs: 0, md: "10px" },
          padding: { xs: 0, md: "40px" },
        }}
        container
        spacing="20px"
      >
        <Grid size={12}>
          <Typography
            component="h2"
            variant="landingH3"
            sx={{
              color: darkMode ? "brand.contrastText" : "brand.main",
              marginLeft: { xs: "20px", md: "auto" },
              paddingBottom: "20px",
            }}
          >
            How does it work?
          </Typography>
        </Grid>
        <Grid
          size={{ xs: 0, lg: 6 }}
          sx={{ display: { xs: "none", lg: "flex" } }}
        >
          <Card
            variant="landing"
            sx={{
              border: `1px solid ${alpha(darkMode ? theme.palette.brand.contrastText : theme.palette.brand.main, 0.4)}`,
            }}
          >
            <Box
              alt={
                darkMode
                  ? "A person sitting in a kitchen, using a tablet, while drinking a hot drink."
                  : "A man seated at a desk, focused on his laptop, browsing in a casual setting."
              }
              component="img"
              src={
                darkMode
                  ? "/static/media/welcome/WomanWithLaptop.png"
                  : "/static/media/welcome/ManWithLaptop.png"
              }
              sx={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "45%",
              }}
            />
          </Card>
        </Grid>
        <Grid container size={{ xs: 12, lg: 6 }}>
          <Grid
            size={{ sm: 12, md: 6 }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <HowDoesItWorkCard
              alertText="Create an account with your NHS login and we can retrieve your records!"
              darkMode={darkMode}
              header="Check your eligibility for a consultation"
              number={"1"}
              text="Answer a short set of questions and share your medical records."
            />
          </Grid>
          <Grid
            size={{ sm: 12, md: 6 }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <HowDoesItWorkCard
              alertText="Your doctor and a team of experts will discuss your case and update you on the decision within 7 days."
              darkMode={darkMode}
              header="Video Consultation"
              number={"2"}
              text="If suitable, be invited to book a video call with a condition specialist to talk about your needs"
            />
          </Grid>
          <Grid
            size={{ sm: 12, md: 6 }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <HowDoesItWorkCard
              alertText="Pay for your medication online and it will be delivered to your home using a secure courier service."
              darkMode={darkMode}
              header="Prescription"
              number={"3"}
              text="If approved, we can send your prescription to Curaleaf Pharmacy, or another pharmacy of your choice."
            />
          </Grid>
          <Grid
            size={{ sm: 12, md: 6 }}
            sx={{ display: { xs: "flex", md: "none" } }}
          >
            <HowDoesItWorkCarousel darkMode={darkMode} />
          </Grid>
          <Grid
            size={{ sm: 12, md: 6 }}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <HowDoesItWorkCard
              alertText="Book appointments, request and track your prescriptions and monitor your health over time."
              darkMode={darkMode}
              header="Patient Care"
              number={"4"}
              text="We're on hand to answer your questions & admin is made simple in your Curaleaf Clinic account."
            />
          </Grid>
          <Grid size={12}>
            <Card
              variant="landing"
              sx={{
                marginLeft: { xs: "20px", md: "0px" },
                marginRight: { xs: "20px", md: "0px" },
                border: `1px solid ${alpha(darkMode ? theme.palette.brand.contrastText : theme.palette.brand.main, 0.4)}`,
                backgroundColor: alpha(theme.palette.brand.main, 0.06),
              }}
            >
              <CardContent sx={{ paddingTop: "20px" }}>
                <Typography
                  component="h3"
                  variant="landingH5"
                  sx={{ color: darkMode ? "brand.contrastText" : "brand.main" }}
                >
                  Watch for how to get started
                </Typography>
                <Box
                  component="iframe"
                  width="100%"
                  height={{ xs: "181px", sm: "460px" }}
                  src="https://www.youtube-nocookie.com/embed/Kr76bP-18Dk?rel=0"
                  sx={{ border: "none", marginTop: "14px" }}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
export default HowDoesItWork;
