import { useQueryClient } from "@tanstack/react-query";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { db } from "src/firebase";
import { AssistedReferral, newAssistedReferral } from "src/models";
import { useMutation, useQuery } from "src/query";

export const useAssistedReferralQuery = () => {
  const { user } = useContext(AuthContext);

  return useQuery<AssistedReferral>({
    queryKey: ["assistedReferral"],
    queryFn: async () => {
      const snapshot = await getDoc(doc(db, "AssistedReferral", user!.id));
      if (snapshot.data() === undefined) {
        throw new Error("Waiting for AR to be created");
      } else {
        return newAssistedReferral(snapshot.data());
      }
    },
    enabled: user !== undefined && user !== null,
  });
};

export const useSetSCRPermissionMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async () => {
      await updateDoc(doc(db, `AssistedReferral/${user!.id}/`), {
        NCRSPermission: true,
        NCRSPermissionObtainedOn: new Date(),
        SOCPermission: true,
        SOCPermssionObtainedOn: new Date(),
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["assistedReferral"] });
    },
  });
};

export const useUpdateAssistedReferralStatusMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (status: string) => {
      await updateDoc(doc(db, `AssistedReferral/${user!.id}`), {
        Status: status,
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["assistedReferral"] });
    },
  });
};
