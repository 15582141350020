import { useQueryClient } from "@tanstack/react-query";
import { createUserWithEmailAndPassword, signOut } from "firebase/auth";
import { httpsCallable } from "firebase/functions";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { auth, functions } from "src/firebase";
import { newAuthUser } from "src/models";
import { useMutation } from "src/query";

const sendMagicLink = httpsCallable(functions, "AppOnCall-sendMagicSignInLink");

export const useSendMagicLinkMutation = () => {
  return useMutation({
    mutationFn: async (email: string) => {
      await sendMagicLink({ email });
    },
  });
};

export const useSignOutMutation = () => {
  const queryClient = useQueryClient();
  const { unauthenticate } = useContext(AuthContext);

  return useMutation({
    mutationFn: async () => await signOut(auth),
    onSuccess: async () => {
      unauthenticate();
      await queryClient.resetQueries();
      queryClient.clear();
    },
  });
};

const sendForgotPasswordEmail = httpsCallable(
  functions,
  "AppOnCall-sendForgotPasswordEmail",
);

export const useForgottenPasswordMutation = () => {
  return useMutation({
    mutationFn: async (email: string) =>
      await sendForgotPasswordEmail({ email }),
  });
};

const resetPassword = httpsCallable(functions, "AppOnCall-resetPasswordNew");

interface IReset {
  authKey: string;
  password: string;
  uid: string;
}

interface IResetResponse {
  success: boolean;
  error?: string;
}

export const useResetPasswordMutation = () => {
  return useMutation({
    mutationFn: async (data: IReset) => {
      const result = await resetPassword(data);
      const responseData: IResetResponse = await (result.data as any);
      return responseData;
    },
  });
};

interface ISignUp {
  email: string;
  password: string;
}

const sendVerificationLinkToEmail = httpsCallable(
  functions,
  "AppOnCall-sendVerificationEmail",
);

export const useSignUpMutation = () => {
  const { authenticate } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (signUpData: ISignUp) => {
      const { user } = await createUserWithEmailAndPassword(
        auth,
        signUpData.email,
        signUpData.password,
      );

      await sendVerificationLinkToEmail({
        email: signUpData.email,
      });

      authenticate(newAuthUser(user));
    },
  });
};

export const useSendVerificationEmailMutation = () => {
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async () => {
      const { data } = await sendVerificationLinkToEmail({
        email: user!.email,
      });
      return (data as any).success;
    },
  });
};
