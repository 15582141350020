import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import ArrowForwardIosSharp from "@mui/icons-material/ArrowForwardIosSharp";
import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";

import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import SplitPage from "src/components/Onboarding/SplitPage";
import ConsentCard from "src/pages/ReviewingRecords/ConsentCard";
import UploadCard from "src/pages/ReviewingRecords/UploadCard";
import {
  useAssistedReferralQuery,
  useNHSDataQuery,
  usePatientQuery,
} from "src/queries";

const ReviewingRecords = () => {
  const [open, setOpen] = useState(true);
  const [expandNext, setExpandNext] = useState(false);
  const { data: assistedReferral } = useAssistedReferralQuery();
  const { data: patient } = usePatientQuery();
  const { data: nhsData } = useNHSDataQuery();

  let summary = <Skeleton />;
  let summarySubtext = null;
  if (nhsData?.retrievingNhsMedicalRecords && !patient?.nhsRecordsObtained) {
    summary = (
      <>
        We are working on retrieving your medical records from the NHS login
        that you consented to share with us.
      </>
    );
  } else if (
    patient?.nhsRecordsObtained &&
    patient.onboardingStatus === "reviewingMedicalInfo"
  ) {
    summary = (
      <>
        We are reviewing the information that you have consented to share with
        us through NHS login as well as any uploaded information and will notify
        you once we have finished our review.
      </>
    );
    summarySubtext = (
      <Typography component="body" variant="body2">
        It is possible that we may not be able to get access to your full record
        through NHS login and in this case we will ask you for further
        information.
      </Typography>
    );
  } else if (assistedReferral?.status === "Promoted to triage") {
    summary = (
      <>
        Your application is currently being reviewed by our team. We will let
        you know if there are any changes.
      </>
    );
  } else if (
    assistedReferral?.socPermission &&
    (assistedReferral?.status === "Awaiting SCR - Sapphire getting from GP" ||
      assistedReferral?.status ===
        "Awaiting SCR - Sapphire getting from GP or from the NCRS")
  ) {
    summary = (
      <>
        We are working to retrieve your medical records and once we have
        retrieved and reviewed them we will notify you.
      </>
    );
  } else if (assistedReferral !== undefined) {
    summary = (
      <>
        We will review the information that you have submitted and will notify
        you once we have finished our review.
      </>
    );
  }

  if (patient?.nhsRecordsObtained && nhsData?.medicalRecordDisabled) {
    summarySubtext = (
      <Collapse in={open}>
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>NHS login</AlertTitle>
          We do not have access to your medical records through NHS login. This
          is due to restrictions in place with your current GP. To remove this
          restriction and allow full access to your medical records please
          contact your GP directly. This will allow us to assess your
          eligibility as quickly as possible.
        </Alert>
      </Collapse>
    );
  }

  return (
    <SplitPage
      left={<OnboardingStepper />}
      right={
        <Stack spacing={2}>
          <Typography variant="h4">{summary}</Typography>
          {summarySubtext}
          {patient ? (
            <List disablePadding>
              <ListItem disableGutters disablePadding>
                <Stack width="100%">
                  <ListItemButton
                    disableGutters
                    sx={{ justifyContent: "space-between" }}
                    onClick={() => setExpandNext(!expandNext)}
                  >
                    <Typography
                      variant="h2"
                      sx={{ color: (theme) => theme.palette.primary.main }}
                    >
                      What happens next?
                    </Typography>

                    <ArrowForwardIosSharp
                      sx={{
                        fontSize: "1.2rem",
                        transform: expandNext
                          ? "rotate(-90deg)"
                          : "rotate(90deg)",
                        color: (theme) => theme.palette.primary.main,
                      }}
                    />
                  </ListItemButton>
                  <Collapse in={expandNext} timeout={"auto"}>
                    <Stack spacing={2}>
                      <Typography>
                        Once we receive your medical information, our clinical
                        team will review your application. <br />
                        Please allow up to three days for your case to be
                        reviewed, then you would be contacted by email. <br />
                        The clinical team will let you know if we need any more
                        information. If we think we have everything we need for
                        an appointment, then you will be invited to book in.
                      </Typography>
                      <Typography variant="h4">Any other questions?</Typography>
                      <Typography>
                        Please see our Resource guide below, with detailed
                        information about the process, costs, medications, and
                        more.
                      </Typography>
                      <Typography>
                        <Link
                          href="https://curaleafclinic.zendesk.com/hc/en-gb"
                          target="_blank"
                        >
                          {" "}
                          Resource Guide
                        </Link>
                      </Typography>
                      <Typography variant="h4">
                        Contact us for anything else
                      </Typography>
                      <List disablePadding>
                        <ListItem disablePadding>
                          <ListItemButton
                            href="mailto:patientsupport@curaleafclinic.com"
                            sx={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>
                              <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText
                              sx={{ wordBreak: "break-word" }}
                              primary="patientsupport@curaleafclinic.com"
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton
                            href="tel:02074594075"
                            sx={{ paddingLeft: 0 }}
                          >
                            <ListItemIcon>
                              <CallIcon />
                            </ListItemIcon>
                            <ListItemText primary="020 7459 4075" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Stack>
                  </Collapse>
                </Stack>
              </ListItem>
            </List>
          ) : null}
          {assistedReferral !== undefined &&
          patient !== undefined &&
          !assistedReferral.socPermission &&
          assistedReferral.status !== "Promoted to triage" ? (
            <ConsentCard />
          ) : null}
          {patient !== undefined ? <UploadCard /> : null}
        </Stack>
      }
      title="Reviewing Records"
    />
  );
};

export default ReviewingRecords;
