import { common } from "@mui/material/colors";
import { alpha, PaletteOptions } from "@mui/material/styles";
import type { Components } from "@mui/material/styles/components";

interface Config {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => {
  return {
    MuiAlert: {
      styleOverrides: {
        icon: {
          color: `${common.white} !important`,
        },
        root: {
          color: common.white,
          borderColor: common.white,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: alpha(common.white, 0.8),
          color: common.black,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "contained" },
              style: {
                backgroundColor: palette.brand?.contrastText,
                color: palette.brand?.main,
                "&:hover": {
                  backgroundColor: alpha(
                    palette.brand?.contrastText ?? common.white,
                    0.8,
                  ),
                },
              },
            },
            {
              props: { variant: "outlined" },
              style: {
                "&:hover": {
                  backgroundColor: alpha(
                    palette.brand?.contrastText ?? common.white,
                    0.16,
                  ),
                },
              },
            },
          ],
          borderColor: palette.brand?.contrastText,
          color: palette.brand?.contrastText,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "onboarding" },
              style: {
                backgroundColor: palette.background?.paper,
              },
            },
          ],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        icon: {
          color: palette.action!.active,
        },
        root: {
          borderColor: palette.neutral![700],
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(palette.brand!.contrastText, 0.16),
        },
      },
    },
  };
};
