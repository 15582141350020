import { ToastContext } from "@curaleaf-international/components";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";

import Dropzone from "src/components/Onboarding/Dropzone";
import YoutubeDialog from "src/pages/MedicalRecords/YoutubeDialog";
import {
  useARDocumentsQuery,
  usePCDocumentsQuery,
  useAssistedReferralQuery,
  useUpdateAssistedReferralStatusMutation,
  useUploadARDocumentsMutation,
  useUploadPCDocumentsMutation,
} from "src/queries";

const UploadCard = () => {
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const [helpLink, setHelpLink] = useState<string | null>(null);
  const { data: assistedReferral } = useAssistedReferralQuery();
  const { data: arDocuments } = useARDocumentsQuery();
  const { data: pcDocuments } = usePCDocumentsQuery();
  const { mutateAsync: uploadAR } = useUploadARDocumentsMutation();
  const { mutateAsync: uploadPC } = useUploadPCDocumentsMutation();
  const { mutateAsync: updateARStatus } =
    useUpdateAssistedReferralStatusMutation();

  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: File[]) => {
    setFiles((files) => [...files, ...newFiles]);
  };

  const onUpload = async () => {
    setLoading(true);
    try {
      if (assistedReferral!.status === "Promoted to triage") {
        await uploadPC(files);
      } else {
        await uploadAR(files);
        await updateARStatus("Medical information awaiting review");
      }
      addToast("Files uploaded successfully", "success");
      setFiles([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <YoutubeDialog
        open={helpLink !== null}
        onClose={() => setHelpLink(null)}
        title="Help fetching your records"
        video={helpLink ?? ""}
      />
      <Card>
        <CardContent>
          <Stack spacing={2}>
            <Typography variant="body1">
              You can upload any additional information directly here if you
              think it would be useful. <br />
              Please avoid uploading password-protected documents to prevent
              further delays in reviewing your application.
            </Typography>
            <ButtonGroup fullWidth size="medium" aria-label="Help options">
              <Button
                color="secondary"
                onClick={() =>
                  setHelpLink(
                    "https://www.youtube-nocookie.com/embed/LQomAkgPGSI",
                  )
                }
                variant="outlined"
              >
                How to get your medical records
              </Button>
              <Button
                color="secondary"
                onClick={() =>
                  setHelpLink(
                    "https://www.youtube-nocookie.com/embed/Q0SCcLtw8JA",
                  )
                }
                variant="outlined"
              >
                How to setup your NHS app
              </Button>
            </ButtonGroup>
            <Dropzone multiple={true} onDrop={onDrop} />
            <List>
              {files.map((file, index) => (
                <ListItem
                  key={`${file.name}-${index}`}
                  secondaryAction={
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() =>
                        setFiles((values) =>
                          values.filter((value) => value !== file),
                        )
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  }
                >
                  <ListItemText primary={file.name} />
                </ListItem>
              ))}
            </List>
            {(arDocuments && arDocuments?.length > 0) ||
            (pcDocuments && pcDocuments?.length > 0) ? (
              <>
                <Typography variant="h4">Uploaded files</Typography>
                <Divider />
              </>
            ) : null}
            <List>
              {assistedReferral?.status !== "Promoted to triage"
                ? arDocuments?.map((filename, index) => (
                    <ListItem key={`${filename}-${index}`}>
                      <ListItemText primary={filename} />
                    </ListItem>
                  ))
                : null}
              {assistedReferral?.status === "Promoted to triage"
                ? pcDocuments?.map((filename, index) => (
                    <ListItem key={`${filename}-${index}`}>
                      <ListItemText primary={filename} />
                    </ListItem>
                  ))
                : null}
            </List>
          </Stack>
        </CardContent>
        <CardActions>
          <LoadingButton
            fullWidth
            disabled={assistedReferral === undefined || files.length === 0}
            loading={loading}
            onClick={onUpload}
            variant="contained"
          >
            Upload files
          </LoadingButton>
        </CardActions>
      </Card>
    </>
  );
};

export default UploadCard;
