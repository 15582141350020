import LoadingButton from "@mui/lab/LoadingButton";
import { OAuthProvider, signInWithRedirect } from "firebase/auth";

import MicrosoftLogo from "src/components/Onboarding/MicrosoftLogo";
import { auth } from "src/firebase";

const MicrosoftSignInButton = () => {
  const onClick = async () => {
    const provider = new OAuthProvider("microsoft.com");
    provider.addScope("email");
    await signInWithRedirect(auth, provider);
  };

  return (
    <LoadingButton
      fullWidth
      onClick={onClick}
      startIcon={<MicrosoftLogo ariaHidden={true} />}
      variant="outlined"
    >
      Continue with Microsoft
    </LoadingButton>
  );
};

export default MicrosoftSignInButton;
