import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Redirect } from "wouter";

import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import SplitPage from "src/components/Onboarding/SplitPage";
import ConsentCard from "src/pages/MedicalRecords/ConsentCard";
import SCRChoiceCard from "src/pages/MedicalRecords/SCRChoiceCard";
import UploadCard from "src/pages/MedicalRecords/UploadCard";
import { usePatientQuery } from "src/queries";

const MedicalRecords = () => {
  const [choice, setChoice] = useState<string | null>(null);
  const { data: patient } = usePatientQuery();

  let content;
  if (patient?.onboardingStatus === "reviewingMedicalInfo") {
    return <Redirect to="/onboarding/reviewing/" />;
  } else if (choice === null) {
    content = <SCRChoiceCard onChange={(value) => setChoice(value)} />;
  } else if (choice === "direct") {
    content = <UploadCard onBack={() => setChoice(null)} />;
  } else {
    content = <ConsentCard onBack={() => setChoice(null)} />;
  }

  return (
    <SplitPage
      left={<OnboardingStepper />}
      right={
        <Stack spacing={2}>
          <Typography variant="h2">Medical Records</Typography>
          {content}
        </Stack>
      }
      title="Medical Records"
    />
  );
};

export default MedicalRecords;
