import { useQuery, useMutation } from "@curaleaf-international/components";
import {
  browserSessionPersistence,
  setPersistence,
  signInWithCustomToken,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { auth, db, functions } from "src/firebase";
import { NHSData, newAuthUser, newNHSData } from "src/models";

const getTokenFromNHSLogin = httpsCallable(
  functions,
  "NHSOnCall-signInWithNHS",
);

interface ISignInData {
  code: string;
  redirectUri: string;
}

export const useNHSDataQuery = () => {
  const { user } = useContext(AuthContext);

  return useQuery<NHSData | null>({
    queryKey: ["nhsData"],
    queryFn: async () => {
      const snapshot = await getDoc(doc(db, "NHSData", user!.id));
      if (snapshot.data() === undefined) {
        return null;
      } else {
        return newNHSData(snapshot.data());
      }
    },
    enabled: user !== undefined && user !== null,
  });
};

export const useNHSSignInMutation = () => {
  const { authenticate } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (data: ISignInData) => {
      const result = await getTokenFromNHSLogin({
        code: data.code,
        redirectURI: data.redirectUri,
      });
      const { success, authToken, error } = result.data as any;
      if (!success) {
        throw new Error(error);
      }
      await setPersistence(auth, browserSessionPersistence);
      const { user } = await signInWithCustomToken(auth, authToken);
      authenticate(newAuthUser(user));
    },
    retry: false,
  });
};
