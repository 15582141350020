import { SelectField } from "@curaleaf-international/components";
import { SxProps } from "@mui/system";

interface IProps {
  disabled?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  label: string;
  name: string;
  required?: boolean;
  sx?: SxProps;
}

const OCCUPATIONS = [
  { value: "Armed forces occupations" },
  { value: "Craft and related trades workers" },
  { value: "Clerical support workers" },
  { value: "Elementary occupations" },
  { value: "Heavy goods vehicle (HGV) driver" },
  { value: "Managers" },
  { value: "Plant and machine operators, and assemblers" },
  { value: "Professional" },
  { value: "Retired" },
  { value: "Service and sales workers" },
  { value: "Skilled agricultural, forestry and fishery workers" },
  { value: "Technicians and associate professionals" },
  { value: "Unemployed" },
  { value: "Other occupations" },
];

const OccupationSelectField = (props: IProps) => {
  return <SelectField {...props} options={OCCUPATIONS} />;
};

export default OccupationSelectField;
