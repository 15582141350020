import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";

import HowDoesItWorkCard from "src/components/LandingPage/HowDoesItWorkCard";

interface IProps {
  darkMode: boolean;
}

const HowDoesItWorkCarousel = ({ darkMode }: IProps) => {
  const [emblaRef, emblaApi] = useEmblaCarousel();
  const [selectedIndex, setSelectedIndex] = useState(0);

  const theme = useTheme();
  const dotButtonCSS = {
    width: "8px",
    height: "8px",
    border: "0.5px solid",
    borderRadius: "10px",
    borderColor: darkMode
      ? alpha(theme.palette.brand.contrastText, 0.4)
      : alpha(theme.palette.brand.main, 0.4),
  };
  const dotButtonClick = (button: number) => {
    emblaApi?.scrollTo(button);
    setSelectedIndex(button);
  };

  const onSelect = useCallback(() => {
    if (emblaApi) {
      setSelectedIndex(emblaApi.selectedScrollSnap());
    }
  }, [emblaApi]);

  useEffect(() => {
    if (emblaApi) {
      emblaApi.on("select", onSelect);
      onSelect();
    }
  }, [emblaApi, onSelect]);

  return (
    <Stack direction="column" width="100%" spacing={2}>
      <Box ref={emblaRef} sx={{ overflow: "hidden", width: "100%" }}>
        <Box sx={{ display: "flex", gridColumnGap: "14px" }}>
          <Box
            sx={{
              flex: "0 0 85%",
              marginLeft: "20px",
            }}
          >
            <HowDoesItWorkCard
              alertText="Create an account with your NHS login and we can retrieve your records!"
              darkMode={darkMode}
              header="Check your eligibility for a consultation"
              number={"1"}
              text="Answer a short set of questions and share your medical records."
            />
          </Box>
          <Box sx={{ flex: "0 0 85%" }}>
            <HowDoesItWorkCard
              alertText="Your doctor and a team of experts will discuss your case and update you on the decision within 7 days."
              darkMode={darkMode}
              header="Video Consultation"
              number={"2"}
              text="If suitable, be invited to book a video call with a condition specialist to talk about your needs"
            />
          </Box>
          <Box sx={{ flex: "0 0 85%" }}>
            <HowDoesItWorkCard
              alertText="Pay for your medication online and it will be delivered to your home using a secure courier service."
              darkMode={darkMode}
              header="Prescription"
              number={"3"}
              text="If approved, we can send your prescription to Curaleaf Pharmacy, or another pharmacy of your choice."
            />
          </Box>
          <Box sx={{ flex: "0 0 85%", marginRight: "20px" }}>
            <HowDoesItWorkCard
              alertText="Book appointments, request and track your prescriptions and monitor your health over time."
              darkMode={darkMode}
              header="Patient Care"
              number={"4"}
              text="We're on hand to answer your questions & admin is made simple in your Curaleaf Clinic account."
            />
          </Box>
        </Box>
      </Box>
      <Stack
        width="100%"
        direction="row"
        spacing="6px"
        justifyContent="center"
        alignContent="center"
        alignItems="items"
      >
        <Box
          component="div"
          sx={{
            ...dotButtonCSS,
            backgroundColor:
              selectedIndex === 0
                ? darkMode
                  ? theme.palette.brand.contrastText
                  : theme.palette.brand.main
                : darkMode
                  ? alpha(theme.palette.brand.main, 0.02)
                  : alpha(theme.palette.brand.main, 0.06),
          }}
          key={0}
          onClick={() => dotButtonClick(0)}
        />
        <Box
          component="div"
          sx={{
            ...dotButtonCSS,
            backgroundColor:
              selectedIndex === 1
                ? darkMode
                  ? theme.palette.brand.contrastText
                  : theme.palette.brand.main
                : darkMode
                  ? alpha(theme.palette.brand.main, 0.02)
                  : alpha(theme.palette.brand.main, 0.06),
          }}
          key={1}
          onClick={() => dotButtonClick(1)}
        />
        <Box
          component="div"
          sx={{
            ...dotButtonCSS,
            backgroundColor:
              selectedIndex === 2
                ? darkMode
                  ? theme.palette.brand.contrastText
                  : theme.palette.brand.main
                : darkMode
                  ? alpha(theme.palette.brand.main, 0.02)
                  : alpha(theme.palette.brand.main, 0.06),
          }}
          key={2}
          onClick={() => dotButtonClick(2)}
        />
        <Box
          component="div"
          sx={{
            ...dotButtonCSS,
            backgroundColor:
              selectedIndex === 3
                ? darkMode
                  ? theme.palette.brand.contrastText
                  : theme.palette.brand.main
                : darkMode
                  ? alpha(theme.palette.brand.main, 0.02)
                  : alpha(theme.palette.brand.main, 0.06),
          }}
          key={3}
          onClick={() => dotButtonClick(3)}
        />
      </Stack>
    </Stack>
  );
};
export default HowDoesItWorkCarousel;
