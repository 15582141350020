import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { Fragment } from "react/jsx-runtime";

interface IProps {
  image: string;
  left?: number;
  link: string;
  title: string;
  scaleUp?: number;
}
const ConditionCard = ({ image, left, link, scaleUp, title }: IProps) => {
  const theme = useTheme();
  return (
    <Card
      variant="landing"
      sx={{
        border: `1px solid ${alpha(theme.palette.brand.contrastText, 0.4)}`,
        backgroundColor: alpha(theme.palette.brand.contrastText, 0.06),
        width: "100%",
        transition: theme.transitions.create("all", {
          duration: theme.transitions.duration.standard,
        }),
        "&:hover": {
          backgroundColor: alpha(theme.palette.brand.contrastText, 0.1),
          border: `1px solid ${alpha(theme.palette.brand.contrastText, 1)}`,
        },
      }}
    >
      <Link
        href={link}
        underline="none"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CardContent
          sx={{
            height: "100%",
            width: "100%",
            padding: { xs: "16px", md: "16px 20px" },
          }}
        >
          <Stack
            justifyContent="space-between"
            sx={{
              height: { xs: "88px", md: "130px", lg: "100%" },
            }}
            direction={{ xs: "row-reverse", lg: "column" }}
          >
            <Box
              alt=""
              component="img"
              src={image}
              sx={{
                opacity: 0.4,
                scale: { xs: scaleUp ? 2 : 1.5, lg: scaleUp ?? 1 },
                position: "relative",
                left: { xs: 0, lg: left ?? 0 },
                top: { xs: 5, lg: left ? -2 : 1 },
              }}
            />
            <Typography
              variant="landingH5"
              sx={{
                color: theme.palette.brand.contrastText,
                wordBreak: "break-word",
                hyphens: "auto",
                overflowWrap: "break-word",
                alignSelf: { xs: "center", lg: "flex-start" },
              }}
            >
              {title.split(" ").map((word, index) => (
                <Fragment key={index}>
                  {word}
                  <br />
                </Fragment>
              ))}
            </Typography>
          </Stack>
        </CardContent>
      </Link>
    </Card>
  );
};
export default ConditionCard;
