import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import BenefitChip from "src/components/LandingPage/BenefitChip";
import TrustpilotWidget from "src/components/LandingPage/TrustpilotWidget";

const Opening = () => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";

  return (
    <Box>
      <Card
        variant="landing"
        sx={{
          background: "none",
          boxShadow: "none",
          height: { xs: "98vh", sm: "98vh" },
          border: "none",
          margin: { xs: "5px", sm: "10px" },
        }}
      >
        <Box sx={{ height: "100%", width: "100%", position: "relative" }}>
          <Box
            alt=""
            component="img"
            src="/static/media/welcome/OpeningBlurred.png"
            sx={{
              width: "100%",
              height: "100%",
              zIndex: -2,
              position: "absolute",
              objectFit: "cover",
            }}
          />
          <Box
            sx={{
              width: "100%",
              height: "100%",
              zIndex: -1,
              position: "absolute",
              background: darkMode
                ? `linear-gradient(180deg, rgba(17, 25, 39, 0.075) 0%, rgba(17, 25, 39, 1) 100%)`
                : `linear-gradient(180deg, rgba(36, 77, 90, 0.25) 0%, rgba(36, 77, 90, 1) 100%)`,
            }}
          />
          <Stack
            sx={{
              justifyContent: "space-between",
              height: "100%",
              width: "100%",
              padding: { xs: "15px 18px", md: "20px 40px" },
            }}
          >
            <Stack
              sx={{
                zIndex: 0,
                width: "100%",
                marginBottom: { xs: "20px", md: "12px" },
              }}
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Box
                alt=""
                component="img"
                src="/static/media/welcome/ClinicLinearWhiteLogo.png"
                width={{ xs: "150px", sm: "180px" }}
              />
              <Stack direction="row" spacing="10px">
                <Button
                  href="https://app.curaleafclinic.com/sign-in/"
                  target="_blank"
                  size="large"
                  variant="outlined"
                  sx={{
                    borderColor: theme.palette.brand.contrastText,
                    color: theme.palette.brand.contrastText,
                    display: { xs: "none", md: "flex" },
                    padding: "12px 28px",
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: 1.25,
                  }}
                >
                  Patient login
                </Button>
                <Button
                  href="https://app.curaleafclinic.com/sign-up/"
                  target="_blank"
                  variant="contained"
                  size="large"
                  sx={{
                    padding: "12px 28px",
                    borderColor: theme.palette.brand.contrastText,
                    backgroundColor: theme.palette.brand.contrastText,
                    color: darkMode
                      ? theme.palette.neutral[1000]
                      : theme.palette.brand.main,
                    fontSize: "1.125rem",
                    fontWeight: 500,
                    lineHeight: 1.25,
                    "&:hover": {
                      backgroundColor: alpha(
                        theme.palette.brand.contrastText,
                        0.8,
                      ),
                    },
                  }}
                >
                  New patients
                </Button>
              </Stack>
            </Stack>
            <Typography
              component="h1"
              variant="landingH2"
              sx={{
                color: "brand.contrastText",
                textAlign: "center",
                justifyContent: "center",
                whiteSpace: { xs: "normal", lg: "pre-line" },
                height: "100%",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <Box
                component="span"
                sx={{ display: { xs: "none", lg: "inline" } }}
              >
                Putting you <br /> back in control
              </Box>
              <Box
                component="span"
                sx={{ display: { xs: "inline", lg: "none" } }}
              >
                Putting <br /> you back <br /> in control
              </Box>
            </Typography>
            <Box>
              <Box
                alt="A man walking while holding a bag in his hand, glancing to his left."
                component="img"
                src="/static/media/welcome/OpeningCutout.png"
                sx={{
                  height: { xs: "80%", md: "90%", lg: "100%" },
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "bottom",
                  position: "absolute",
                  top: { xs: 200, sm: 270, md: 320, lg: 200, xl: 210 },
                  overflow: "visible",
                  left: 50,
                  transform: "scale(-1, 1)",
                  zIndex: 0,
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  zIndex: 1,
                  background: `linear-gradient(180deg, rgba(36, 77, 90, 0.016)0%, rgba(36, 77, 90, 0.2)100%)`,
                }}
              />
            </Box>
            <Stack
              direction="row"
              sx={{
                width: "100%",
                justifyContent: "space-between",
                zIndex: 2,
                alignItems: "end",
                paddingBottom: { xs: "6px", lg: "20px" },
              }}
            >
              <Box>
                <Typography
                  component="h2"
                  variant="subtitle1"
                  sx={{
                    color: "brand.contrastText",
                    width: { xs: "90%", sm: "60%", lg: "35%" },
                    marginBottom: "14px",
                  }}
                >
                  At Curaleaf Clinic, medical cannabis can be prescribed by
                  specialist doctors and delivered direct to you.
                </Typography>
                <Stack
                  direction="row"
                  sx={{ display: { xs: "none", lg: "flex" } }}
                  spacing={2}
                >
                  <BenefitChip
                    image="/static/media/welcome/hero_icons/TrophyIcon.png"
                    label="High patient satisfaction"
                  />
                  <BenefitChip
                    image="/static/media/welcome/hero_icons/HeartIcon.png"
                    label="Great value expert care"
                  />
                  <BenefitChip
                    image="/static/media/welcome/hero_icons/MapIcon.png"
                    label="No travel required"
                  />
                </Stack>
              </Box>
              <Box
                alt="A circular badge featuring a drawn leaf in the centre, surrounded by text that reads 'Medical Clinic'."
                component="img"
                src="/static/media/welcome/OpeningBadge.png"
                sx={{ width: { xs: "120px", sm: "170px" } }}
              />
            </Stack>
          </Stack>
        </Box>
      </Card>
      <TrustpilotWidget />
    </Box>
  );
};
export default Opening;
