export const neutral = {
  20: "#11192705",
  50: "#1119270F",
  100: "#11192719",
  150: "#11192728",
  200: "#11192733",
  300: "#1119274C",
  400: "#11192766",
  500: "#11192780",
  600: "#11192799",
  700: "#111927B3",
  800: "#111927CC",
  900: "#111927E6",
  1000: "#111927",
};

export const darkGreyBlue = {
  main: "#244D5A",
  contrastText: "#FFFFFF",
};

export const electricBlue = {
  main: "#0094CA",
  contrastText: "#FFFFFF",
};

export const fadedRed = {
  main: "#DC3545",
  contrastText: "#FFFFFF",
};

export const papayaOrange = {
  main: "#F05E23",
  contrastText: "#FFFFFF",
};

export const irishGreen = {
  main: "#00893E",
  contrastText: "#FFFFFF",
};
