import { z } from "zod";

const pendingAssistedReferralSchema = z
  .object({
    AddressLine1: z.string().optional(),
    AddressLine2: z.string().optional(),
    AppointmentCommunicationPreferences: z.array(z.string()).optional(),
    CgEmail: z.union([z.literal(""), z.string().email()]).optional(),
    CgFirstName: z.string().optional(),
    CgLastName: z.string().optional(),
    CgPhoneNumber: z.string().optional(),
    CgTitle: z.string().optional(),
    City: z.string().optional(),
    County: z.string().optional(),
    ClinicName: z.string().optional(),
    Country: z.string().optional(),
    DoB: z
      .union([z.literal("").transform(() => null), z.coerce.date()])
      .optional(),
    Email: z.string().email().optional(),
    FirstName: z.string().optional(),
    Gender: z.string().optional(),
    GpAddressLine1: z.string().optional(),
    GpAddressLine2: z.string().optional(),
    GpCity: z.string().optional(),
    GpEmail: z.union([z.literal(""), z.string().email()]).optional(),
    GpName: z.string().optional(),
    GpPhonenumber: z.string().optional(),
    GpPostcode: z.string().optional(),
    How_hear_about_us: z.string().optional(),
    IsTransfer: z.boolean().optional(),
    KnownAllergies: z.string().optional(),
    LastName: z.string().optional(),
    MarketingConsent: z.boolean().optional(),
    MedHist: z.string().optional(),
    Veteran: z.boolean().optional(),
    Occupation: z.string().optional(),
    PainHist: z.string().optional(),
    PartnerCode: z.string().optional(),
    Phonenumber: z.string().optional(),
    PorG: z.string().optional(),
    Postcode: z.string().optional(),
    PsychHist: z.string().optional(),
    Reason: z.string().optional(),
    RefFrom: z.string().optional(),
    Title: z.string().optional(),
  })
  .transform((value) => ({
    addressLine1: value.AddressLine1 ?? null,
    addressLine2: value.AddressLine2 ?? null,
    allergies: value.KnownAllergies ?? null,
    appointmentCommunicationPreferences:
      value.AppointmentCommunicationPreferences ?? null,
    attribution: value.How_hear_about_us ?? null,
    carer: value.PorG ?? null,
    carerEmail: value.CgEmail ?? null,
    carerPhonenumber: value.CgPhoneNumber ?? null,
    carerFirstName: value.CgFirstName ?? null,
    carerLastName: value.CgLastName ?? null,
    carerTitle: value.CgTitle ?? null,
    city: value.City ?? null,
    county: value.County ?? null,
    clinicName: value.ClinicName ?? null,
    condition: value.Reason ?? null,
    country: value.Country ?? null,
    dateOfBirth: value.DoB ?? null,
    firstName: value.FirstName ?? null,
    gpAddressLine1: value.GpAddressLine1 ?? null,
    gpAddressLine2: value.GpAddressLine2 ?? null,
    gpCity: value.GpCity ?? null,
    gpEmail: value.GpEmail ?? null,
    gpName: value.GpName ?? null,
    gpPhonenumber: value.GpPhonenumber ?? null,
    gpPostcode: value.GpPostcode ?? null,
    isTransfer: value.IsTransfer ?? null,
    lastName: value.LastName ?? null,
    marketingConsent: value.MarketingConsent ?? false,
    medicineHistory: value.MedHist ?? null,
    veteran: value.Veteran ?? false,
    occupation: value.Occupation ?? null,
    painHistory: value.PainHist ?? null,
    partnerPharmacy: value.PartnerCode ?? null,
    phonenumber: value.Phonenumber ?? null,
    postcode: value.Postcode ?? null,
    psychiatricHistory: value.PsychHist ?? null,
    referrer: value.RefFrom ?? null,
    sex: value.Gender ?? null,
    title: value.Title ?? null,
  }));

export type PendingAssistedReferral = z.infer<
  typeof pendingAssistedReferralSchema
>;

export const newPendingAssistedReferral = (
  data: unknown,
): PendingAssistedReferral => pendingAssistedReferralSchema.parse(data);
