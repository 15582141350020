import { Redirect } from "wouter";

import Loader from "src/components/Onboarding/Loader";
import { usePatientQuery } from "src/queries";

const Onboarding = () => {
  const { data: patient } = usePatientQuery();
  switch (patient?.onboardingStatus) {
    case "pending":
      return <Redirect to="/onboarding/eligibility/" />;
    case "nhsChoice":
      return <Redirect to="/onboarding/nhs-choice/" />;
    case "genInfo":
      return <Redirect to="/onboarding/about-you/" />;
    case "userAddress":
      return <Redirect to="/onboarding/address/" />;
    case "healthInfo":
      return <Redirect to="/onboarding/health/" />;
    case "gpInfo":
      return <Redirect to="/onboarding/gp/" />;
    case "medicalRecordsChoice":
      return <Redirect to="/onboarding/medical-records/" />;
    case "patientRetrievingScr":
    case "reviewingMedicalInfo":
    case "sapphireRetrievingScr":
      return <Redirect to="/onboarding/reviewing/" />;
    case "moreInformationRequiredClinician":
    case "moreInformationRequired":
      return <Redirect to="/onboarding/more-information-required/" />;
    default:
      return <Loader />;
  }
};

export default Onboarding;
