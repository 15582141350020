import { backdropClasses } from "@mui/material/Backdrop";
import { common } from "@mui/material/colors";
import type { PaletteOptions } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import type { Components } from "@mui/material/styles/components";

import { darkGreyBlue, electricBlue } from "src/theme/colors";

interface Config {
  palette: PaletteOptions;
}

export const createComponents = ({ palette }: Config): Components => {
  return {
    MuiAlert: {
      styleOverrides: {
        icon: {
          color: `${darkGreyBlue.main} !important`,
        },
        root: {
          borderColor: darkGreyBlue.main,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: palette.neutral![1000],
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          [`&:not(.${backdropClasses.invisible})`]: {
            backgroundColor: alpha(palette.neutral![1000], 0.75),
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "contained" },
              style: {
                backgroundColor: palette.brand?.main,
                color: palette.brand?.contrastText,
                "&:hover": {
                  backgroundColor: alpha(
                    palette.brand?.main ?? common.white,
                    0.8,
                  ),
                },
              },
            },
            {
              props: { variant: "outlined" },
              style: {
                "&:hover": {
                  backgroundColor: alpha(electricBlue.main, 0.16),
                },
              },
            },
          ],
          borderColor: palette.brand?.main,
          color: palette.brand?.main,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          variants: [
            {
              props: { variant: "onboarding" },
              style: {
                backgroundColor: alpha(
                  palette.background?.paper ?? common.white,
                  0.7,
                ),
              },
            },
          ],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderColor: palette.neutral![200],
          icon: {
            color: palette.action!.active,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: alpha(darkGreyBlue.main, 0.16),
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {},
      },
    },
  };
};
