import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { Redirect } from "wouter";

import { AuthContext } from "src/AuthContext";
import ProcessSidebar from "src/components/Onboarding/ProcessSidebar";
import SplitPage from "src/components/Onboarding/SplitPage";
import { useSendVerificationEmailMutation } from "src/queries";

const EmailUnverified = () => {
  const { user } = useContext(AuthContext);
  const [sent, setSent] = useState(false);
  const { mutateAsync: sendEmail, isPending } =
    useSendVerificationEmailMutation();
  const onClick = async () => {
    try {
      await sendEmail();
      setSent(true);
    } catch {
      // pass
    }
  };

  if (user !== undefined && user === null) {
    return <Redirect to="/" />;
  } else if (user?.emailVerified) {
    return <Redirect to="/onboarding/" />;
  } else {
    return (
      <SplitPage
        left={<ProcessSidebar />}
        right={
          <Stack spacing={3}>
            <Typography variant="h2">Verification Email Sent</Typography>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="body1">
                  We have sent a link to your email address. Click on the link
                  to verify your email.
                </Typography>
                <Typography variant="body1">
                  If you can not find this email please check your junk mail.
                </Typography>
              </CardContent>
              <CardActions>
                <LoadingButton
                  color="primary"
                  disabled={sent}
                  fullWidth
                  onClick={onClick}
                  loading={isPending}
                  variant="contained"
                >
                  {sent ? "Email sent" : "Resend verification email"}
                </LoadingButton>
              </CardActions>
            </Card>
          </Stack>
        }
        title="Email Verification Required"
      />
    );
  }
};

export default EmailUnverified;
