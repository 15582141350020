import { z } from "zod";

const nhsDataSchema = z
  .object({
    errorRetrievingMedicalRecords: z.boolean().default(false),
    patientSettings: z
      .object({
        medicalRecordEnabled: z
          .boolean()
          .nullable()
          .transform((value) => value ?? false),
        repeatPrescriptionsEnabled: z
          .boolean()
          .nullable()
          .transform((value) => value ?? false),
      })
      .nullable()
      .optional(),
    retrievingMedicalRecords: z.boolean().default(false),
  })
  .transform((value) => ({
    errorRetrievingMedicalRecords: value.errorRetrievingMedicalRecords,
    medicalRecordDisabled: !(
      value.patientSettings?.medicalRecordEnabled ?? false
    ),
    repeatPrescriptionsDisabled: !(
      value.patientSettings?.repeatPrescriptionsEnabled ?? false
    ),
    retrievingNhsMedicalRecords: value.retrievingMedicalRecords,
  }));

export type NHSData = z.infer<typeof nhsDataSchema>;

export const newNHSData = (data: unknown): NHSData => nhsDataSchema.parse(data);
