import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import ConditionCard from "src/components/LandingPage/ConditionCard";

const Conditions = () => {
  const theme = useTheme();
  const darkMode = theme.palette.mode === "dark";

  return (
    <Box>
      <Card
        variant="landing"
        sx={{
          backgroundColor: darkMode
            ? theme.palette.neutral[900]
            : theme.palette.brand.main,
          border: "none",
          color: theme.palette.primary.contrastText,
          margin: { xs: "5px", sm: "10px" },
        }}
      >
        <CardContent
          sx={{
            padding: {
              xs: "50px 15px !important",
              xl: "120px 38px !important",
            },
          }}
        >
          <Stack
            justifyContent="space-between"
            paddingBottom={4}
            spacing={{ xs: "10px", xl: "50px" }}
            direction={{ xs: "column", xl: "row" }}
            width="100%"
            alignItems={{ xs: "flex-start", xl: "flex-end" }}
          >
            <Typography
              component="h2"
              variant="landingH1"
              sx={{
                color: theme.palette.brand.contrastText,
                fontSize: { xs: "60px", sm: "120px", md: "150px", lg: "180px" },
              }}
            >
              Conditions
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: theme.palette.brand.contrastText,
                paddingBottom: "20px",
              }}
            >
              At Curaleaf Clinic, we see patients looking for the next step in
              their treatment plan. This can be considered when traditional
              therapies haven't provided sufficient relief or have led to
              unwanted side effects.
            </Typography>
          </Stack>
          <Stack
            direction={{ xs: "column", lg: "row" }}
            spacing={{ xs: "14px", lg: "20px" }}
          >
            <ConditionCard
              image="/static/media/welcome/condition_icons/CancerIconWhite.png"
              left={40}
              link="https://curaleafclinic.com/condition-type/cancer/"
              title="Cancer-related Symptoms"
            />
            <ConditionCard
              image="/static/media/welcome/condition_icons/GastroIconWhite.png"
              left={25}
              link="https://curaleafclinic.com/condition-type/gastrointestinal-conditions/"
              title="Gastrointestinal Conditions"
              scaleUp={1.5}
            />
            <ConditionCard
              image="/static/media/welcome/condition_icons/BrainIconWhite.png"
              link="https://curaleafclinic.com/condition-type/neurological-conditions/"
              title="Neurological Conditions"
            />
            <ConditionCard
              image="/static/media/welcome/condition_icons/PainIconWhite.png"
              left={20}
              link="https://curaleafclinic.com/condition-type/pain-conditions/"
              title="Chronic Pain"
              scaleUp={1.1}
            />
            <ConditionCard
              image="/static/media/welcome/condition_icons/PsychIconWhite.png"
              left={10}
              link="https://curaleafclinic.com/condition-type/psychiatric-conditions/"
              title="Psychiatric Conditions"
            />
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Conditions;
