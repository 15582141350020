import Alert from "@mui/material/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import { alpha, useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

interface IProps {
  alertText: string;
  darkMode: boolean;
  header: string;
  number: string;
  text: string;
}

const HowDoesItWorkCard = ({
  alertText,
  darkMode,
  header,
  number,
  text,
}: IProps) => {
  const theme = useTheme();
  return (
    <Card
      variant="landing"
      sx={{
        height: "100%",
        width: "100%",
        border: `1px solid ${alpha(darkMode ? theme.palette.brand.contrastText : theme.palette.brand.main, 0.4)}`,
        backgroundColor: alpha(theme.palette.brand.main, 0.06),
      }}
    >
      <CardContent sx={{ height: "100%", padding: "0px 20px" }}>
        <Stack
          justifyContent="space-between"
          spacing="30px"
          sx={{ height: "100%" }}
        >
          <Typography
            textAlign="right"
            sx={{
              opacity: "10%",
              fontWeight: 300,
              fontSize: "100px",
              letterSpacing: "-12px",
              lineHeight: "100px",
            }}
          >
            {number.padStart(2, "0")}
          </Typography>
          <Stack justifyContent="space-between" spacing={1} height="100%">
            <Typography
              component="h3"
              variant="landingH5"
              sx={{ color: darkMode ? "brand.contrastText" : "brand.main" }}
            >
              {header}
            </Typography>
            <Typography>{text}</Typography>
            <Alert variant="outlined" severity="info">
              <Typography variant="subtitle2">{alertText}</Typography>
            </Alert>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default HowDoesItWorkCard;
