import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useRef } from "react";

declare global {
  interface Window {
    Trustpilot: {
      loadFromElement: (element: HTMLElement) => void;
    };
  }
}

const TrustpilotWidget = () => {
  const darkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const mobile = useMediaQuery("(max-width: 600px)");
  const ref = useRef(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js";
    script.async = true;

    script.onload = () => {
      if (window.Trustpilot && ref.current) {
        window.Trustpilot.loadFromElement(ref.current);
      }
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box sx={{ marginTop: "14px" }}>
      <Box
        className="trustpilot-widget"
        data-locale="en-GB"
        data-template-id="5419b6ffb0d04a076446a9af"
        data-businessunit-id="65aa711951242ae96f5216ae"
        data-theme={darkMode ? "dark" : "light"}
        data-style-height={mobile ? "48px" : "30px"}
        data-style-width="100%"
        ref={ref}
        rel="noopener"
        component="a"
        href="https://uk.trustpilot.com/review/curaleafclinic.com"
        sx={{
          colorScheme: "auto",
        }}
      />
    </Box>
  );
};

export default TrustpilotWidget;
