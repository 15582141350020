import { createElement as h, ReactElement, useContext } from "react";
import { DefaultParams, Redirect, RouteProps, useRoute } from "wouter";

import { AuthContext } from "src/AuthContext";
import Loader from "src/components/Onboarding/Loader";
import { AuthUser, Patient } from "src/models";
import { usePatientQuery } from "src/queries";

type SwitchedProps<T extends DefaultParams> = {
  match?: any;
  redirectCheck: (user: AuthUser, patient: Patient) => string | null;
} & RouteProps<T>;

const PrivateRoute = <T extends DefaultParams>({
  path,
  match,
  component,
  children,
  redirectCheck,
}: SwitchedProps<T>): ReactElement | null => {
  const { user } = useContext(AuthContext);
  const { data: patient } = usePatientQuery();
  const useRouteMatch = useRoute(path as string);

  // `props.match` is present - Route is controlled by the Switch
  const [matches, params] = match || useRouteMatch;

  if (!matches) return null;

  if (user === null) {
    return <Redirect to="/" />;
  } else if (user === undefined || patient === undefined) {
    return <Loader />;
  } else {
    const to = redirectCheck(user, patient);
    if (to !== null) {
      return <Redirect to={to} replace={true} />;
    } else {
      // React-Router style `component` prop
      if (component) return h(component, { params });

      // support render prop or plain children
      return (
        typeof children === "function" ? children(params) : children
      ) as ReactElement;
    }
  }
};

export default PrivateRoute;
