import {
  CheckboxField,
  SubmitButton,
} from "@curaleaf-international/components";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as yup from "yup";

import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import SplitPage from "src/components/Onboarding/SplitPage";
import {
  useDeclareEligibleMutation,
  usePatientQuery,
  useUpdateOnboardingStatusMutation,
} from "src/queries";
import { tagEvent } from "src/utils";

interface IForm {
  acceptance: boolean;
  declaration: boolean;
  marketingConsent: boolean;
}

const validationSchema = yup.object({
  acceptance: yup.boolean().required().oneOf([true], "This must be accepted."),
  declaration: yup.boolean().required().oneOf([true], "This must be accepted."),
  marketingConsent: yup.boolean().required(),
});

const Eligibility = () => {
  const [_, setLocation] = useLocation();
  const { data: patient } = usePatientQuery();
  const { mutateAsync: declareEligible } = useDeclareEligibleMutation();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const methods = useForm<IForm>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      acceptance: false,
      declaration: false,
      marketingConsent: false,
    },
  });

  const onSubmit = async (data: IForm) => {
    await declareEligible({
      communicationPreferences: ["email", "text", "phone"],
      marketingConsent: data.marketingConsent,
      referrer:
        localStorage.getItem("referrer")?.toUpperCase() ?? "CURALEAF CLINIC",
    });
    if (patient!.onboardingStatus === "pending") {
      await updateOnboardingStatus("genInfo");
      tagEvent("Eligibility Form Started", {
        category: "Onboarding",
        label: `Started at ${new Date().toISOString()}`,
      });
    }
    setLocation("/onboarding/about-you/");
  };

  return (
    <SplitPage
      left={<OnboardingStepper />}
      right={
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Typography variant="h2">Eligibility Assessment</Typography>
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant="body1">
                      Our application process is quite straightforward and can
                      usually be completed within 5 to 10 minutes. After
                      completing your application, we will contact you to
                      confirm whether you are likely to benefit from medical
                      cannabis as a treatment option and if appropriate, invite
                      you for a consultation with one of our clinicians.
                    </Typography>
                    <Typography>
                      To view our privacy policy click{" "}
                      <Link
                        href="https://www.curaleafclinic.com/privacy-policy/v2/"
                        target="_blank"
                      >
                        here
                      </Link>{" "}
                    </Typography>
                    <CheckboxField
                      label="I understand that my information will be sent to Curaleaf Clinic and that they may contact me by phone, email or SMS going forward in relation to my application and treatment."
                      name="acceptance"
                    />
                    <CheckboxField
                      label="I understand that in order to be considered for potential treatment with cannabis based medical products that proof of a condition (a medical diagnosis) and proof of treatment for that condition under the direction of a medical professional will be required. Methods to share medical records are explained in the application."
                      name="declaration"
                    />
                    <CheckboxField
                      label="I would like to receive regular marketing email communications and updates from Curaleaf Clinic."
                      name="marketingConsent"
                    />

                    <Typography variant="body2">
                      By pressing the "Start" button , I confirm that I am aware
                      of the costs associated with treatment including likely
                      appointment fees and prescription costs.
                      <br />
                      Please click{" "}
                      <Link
                        href="https://www.curaleafclinic.com/pricing/"
                        target="_blank"
                      >
                        here
                      </Link>{" "}
                      to learn more about pricing if you are unsure.
                    </Typography>
                  </Stack>
                </CardContent>
                <CardActions>
                  <SubmitButton
                    disabled={
                      patient === undefined ||
                      patient.onboardingStatus !== "pending"
                    }
                    fullWidth
                    label="Start"
                  />
                </CardActions>
              </Card>
            </Stack>
          </form>
        </FormProvider>
      }
      title="Eligibility"
    />
  );
};

export default Eligibility;
