import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const Blurb = () => {
  return (
    <Stack
      sx={{
        paddingLeft: { xs: "20px", md: "50px" },
        paddingRight: { xs: "20px", md: "50px" },
        width: { xs: "100%", md: "65%" },
        spacing: { xs: "12px", md: "24px" },
      }}
    >
      <Typography
        component="h3"
        paddingBottom={{ xs: "12px", sm: "24px" }}
        variant="landingH4"
      >
        Specialist doctors can prescribe for patients with a wide range of
        diagnosed conditions, including chronic pain, mental health and
        neurological disorders.
      </Typography>
      <Typography component="h4" variant="subtitle1">
        You may be eligible for a consultation if you have a diagnosed physical
        or mental health condition that has not been relieved by traditional
        therapies.
      </Typography>
    </Stack>
  );
};
export default Blurb;
