import {
  EmailField,
  SubmitButton,
  TextField,
} from "@curaleaf-international/components";
import { yupResolver } from "@hookform/resolvers/yup";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "wouter";
import * as yup from "yup";

import { AuthContext } from "src/AuthContext";
import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import SplitPage from "src/components/Onboarding/SplitPage";
import { GP } from "src/models";
import GPPicker from "src/pages/AboutGP/GPPicker";
import {
  usePendingAssistedReferralQuery,
  usePatientQuery,
  useSetPendingARCompleteMutation,
  useUpdateGPInfoMutation,
  useUpdateOnboardingStatusMutation,
} from "src/queries";
import { fbTrack, sleep, snapTrack, tagEvent } from "src/utils";

interface IForm {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  email?: string;
  name: string;
  phoneNumber?: string;
  postcode: string;
}

const validationSchema = yup.object({
  addressLine1: yup.string(),
  addressLine2: yup.string(),
  city: yup.string(),
  email: yup.string(),
  name: yup.string().required(),
  phoneNumber: yup.string(),
  postcode: yup.string().required(),
});

const AboutGP = () => {
  const { user } = useContext(AuthContext);
  const { data: patient } = usePatientQuery();
  const { data: assistedReferral } = usePendingAssistedReferralQuery();
  const { mutateAsync: update } = useUpdateGPInfoMutation();
  const { mutateAsync: completePendingAR } = useSetPendingARCompleteMutation();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const [_, setLocation] = useLocation();

  const defaultValues = {
    addressLine1: assistedReferral?.gpAddressLine1 ?? "",
    addressLine2: assistedReferral?.gpAddressLine2 ?? "",
    city: assistedReferral?.gpCity ?? "",
    email: assistedReferral?.gpEmail ?? "",
    name: assistedReferral?.gpName ?? "",
    phoneNumber: assistedReferral?.gpPhonenumber ?? "",
    postcode: assistedReferral?.gpPostcode ?? "",
  };

  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  useEffect(() => {
    if (!methods.formState.isSubmitting) {
      methods.reset(defaultValues);
    }
  }, [assistedReferral, methods.reset, methods.formState.isSubmitting]);

  const onSelect = (gp: GP) => {
    methods.reset({
      addressLine1: gp.addressLine1 ?? "",
      addressLine2: gp.addressLine2 ?? "",
      city: gp.city ?? "",
      email: gp.email ?? "",
      name: gp.name,
      phoneNumber: gp.phoneNumber ?? "",
      postcode: gp.postcode,
    });
  };

  const onSubmit = async (data: IForm) => {
    await update({
      addressLine1: data.addressLine1 ?? "",
      addressLine2: data.addressLine2 ?? "",
      city: data.city ?? "",
      email: data.email ?? "",
      name: data.name,
      phoneNumber: data.phoneNumber ?? "",
      postcode: data.postcode,
    });

    await completePendingAR();

    if (patient!.onboardingStatus === "gpInfo") {
      fbTrack("AssistedReferralComplete");
      snapTrack("SIGN_UP");
      await updateOnboardingStatus(
        patient?.nhsRecordsObtained
          ? "reviewingMedicalInfo"
          : "medicalRecordsChoice",
      );
      tagEvent("Eligibility Form Finished", {
        category: "Onboarding",
        label: `ID: ${user!.id}`,
      });
    }
    await sleep(3000);
    if (patient?.nhsRecordsObtained) {
      setLocation("/onboarding/reviewing/");
    } else {
      setLocation("/onboarding/medical-records/");
    }
  };

  return (
    <SplitPage
      left={<OnboardingStepper />}
      right={
        <Stack spacing={2}>
          <Typography variant="h2">About your GP</Typography>
          <Card>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <CardContent>
                  <Stack spacing={2}>
                    <GPPicker onSelect={onSelect} />
                    <Typography variant="body2">
                      If you cannot find your GP using the postcode search
                      above, or if you live in Scotland or Wales, you can use
                      the{" "}
                      <Link
                        href="https://www.nhs.uk/service-search/find-a-gp/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        NHS England
                      </Link>
                      ,{" "}
                      <Link
                        href="https://www.nhsinform.scot/scotlands-service-directory/gp-practices"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        NHS Scotland
                      </Link>
                      , or{" "}
                      <Link
                        href="https://111.wales.nhs.uk/localservices/?s=GPSurgeries&pc=n&sort=default"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        NHS Wales
                      </Link>{" "}
                      directories to find the relevant information needed to
                      complete the fields below.
                    </Typography>
                    <Divider />
                    <TextField
                      fullWidth
                      label="GP's Name"
                      name="name"
                      required
                    />
                    <TextField
                      fullWidth
                      label="GP's Address Line 1"
                      name="addressLine1"
                      required
                    />
                    <TextField
                      fullWidth
                      label="GP's Address Line 2"
                      name="addressLine2"
                    />
                    <TextField
                      fullWidth
                      label="GP's City"
                      name="city"
                      required
                    />
                    <TextField
                      fullWidth
                      label="GP's Postcode"
                      name="postcode"
                      required
                    />
                    <EmailField
                      fullWidth
                      label="GP's email"
                      name="email"
                      placeholder="If unknown, please leave blank."
                    />
                    <TextField
                      fullWidth
                      label="GP's Phone Number"
                      name="phoneNumber"
                      required
                    />
                  </Stack>
                </CardContent>
                <CardActions>
                  <SubmitButton
                    disabled={patient === undefined}
                    fullWidth
                    label="Continue"
                  />
                </CardActions>
              </form>
            </FormProvider>
          </Card>
        </Stack>
      }
      title="About your GP"
    />
  );
};

export default AboutGP;
