import { useQueryClient } from "@tanstack/react-query";
import { collection, doc, getDocs, setDoc } from "firebase/firestore";
import { ref, uploadBytesResumable } from "firebase/storage";
import { useContext } from "react";

import { AuthContext } from "src/AuthContext";
import { db, storage } from "src/firebase";
import { useMutation, useQuery } from "src/query";

export const usePCDocumentsQuery = () => {
  const { user } = useContext(AuthContext);

  return useQuery<string[]>({
    queryKey: ["patientComplianceDocuments"],
    queryFn: async () => {
      const snapshot = await getDocs(
        collection(db, `/PCompliance/${user!.id}/FMHistory`),
      );
      return snapshot.docs.map((doc) => doc.data().description);
    },
    enabled: user !== undefined && user !== null,
  });
};

export const useUploadPCDocumentsMutation = () => {
  const queryClient = useQueryClient();
  const { user } = useContext(AuthContext);

  return useMutation({
    mutationFn: async (files: File[]) => {
      const timestamp = new Date().getTime();

      await Promise.all(
        files.map(async (file: File, index: number) => {
          const fileRef = ref(
            storage,
            `/PCompliance/${user!.id}/FMHistory/${timestamp + index}`,
          );
          await uploadBytesResumable(fileRef, file);
          await setDoc(
            doc(db, `/PCompliance/${user!.id}/FMHistory/${timestamp + index}`),
            {
              TS: (timestamp + index).toString(),
              description: file.name,
            },
          );
        }),
      );
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["patientComplianceDocuments"],
      });
    },
  });
};
