import { ToastContext } from "@curaleaf-international/components";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";

import Dropzone from "src/components/Onboarding/Dropzone";
import OnboardingStepper from "src/components/Onboarding/OnboardingStepper";
import SplitPage from "src/components/Onboarding/SplitPage";
import {
  useARDocumentsQuery,
  useAssistedReferralQuery,
  useNHSDataQuery,
  usePCDocumentsQuery,
  usePatientQuery,
  useUpdateAssistedReferralStatusMutation,
  useUpdateOnboardingStatusMutation,
  useUploadARDocumentsMutation,
  useUploadPCDocumentsMutation,
} from "src/queries";

const MoreInformationRequired = () => {
  const { addToast } = useContext(ToastContext);
  const [open, setOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const { data: patient } = usePatientQuery();
  const { data: assistedReferral } = useAssistedReferralQuery();
  const { data: nhsData } = useNHSDataQuery();
  const { mutateAsync: updateARStatus } =
    useUpdateAssistedReferralStatusMutation();
  const { mutateAsync: updateOnboardingStatus } =
    useUpdateOnboardingStatusMutation();
  const { data: arDocuments } = useARDocumentsQuery();
  const { data: pcDocuments } = usePCDocumentsQuery();
  const { mutateAsync: uploadAR } = useUploadARDocumentsMutation();
  const { mutateAsync: uploadPC } = useUploadPCDocumentsMutation();

  let summary = <Skeleton />;
  let summarySubtext = null;
  if (nhsData?.retrievingNhsMedicalRecords && !patient?.nhsRecordsObtained) {
    summary = (
      <>
        <Typography>
          We are working on retrieving your medical records from the NHS login
          that you consented to share with us.
        </Typography>
        <Typography>
          Please avoid uploading password-protected documents to prevent further
          delays in reviewing your application.
        </Typography>
      </>
    );
  } else if (patient?.onboardingStatus === "moreInformationRequired") {
    summary = (
      <>
        <Typography>
          We have reviewed your medical records but we require more information
          in order to proceed.
        </Typography>
        <Typography>
          Please avoid uploading password-protected documents to prevent further
          delays in reviewing your application.
        </Typography>
        {assistedReferral?.moreInfo ? (
          <Typography>{assistedReferral.moreInfo}</Typography>
        ) : null}
      </>
    );
  } else if (patient?.onboardingStatus === "moreInformationRequiredClinician") {
    summary = (
      <>
        <Typography>
          A clinician has reviewed your medical records but they require more
          information in order to proceed.
        </Typography>
        <Typography>
          Please avoid uploading password-protected documents to prevent further
          delays in reviewing your application.
        </Typography>
        {patient.moreInfo ? <Typography>{patient.moreInfo}</Typography> : null}
      </>
    );
  }

  if (patient?.nhsRecordsObtained && nhsData?.medicalRecordDisabled) {
    summarySubtext = (
      <Collapse in={open}>
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          <AlertTitle>NHS login</AlertTitle>
          We do not have access to your medical records through NHS login. This
          is due to restrictions in place with your current GP. To remove this
          restriction and allow full access to your medical records please
          contact your GP directly. This will allow us to assess your
          eligibility as quickly as possible.
        </Alert>
      </Collapse>
    );
  }
  // eslint-disable-next-line @typescript-eslint/require-await
  const onDrop = async (newFiles: File[]) => {
    setFiles((files) => [...files, ...newFiles]);
  };

  const onUpload = async () => {
    setLoading(true);
    try {
      if (assistedReferral!.status === "Promoted to triage") {
        await uploadPC(files);
      } else {
        await uploadAR(files);
        await updateOnboardingStatus("reviewingMedicalInfo");
        if (assistedReferral!.status !== "Promoted to triage") {
          await updateARStatus("Medical information awaiting review");
        }
      }
      addToast("Files uploaded successfully", "success");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SplitPage
      left={<OnboardingStepper />}
      right={
        <Stack spacing={2}>
          <Typography component="h2" variant="h2">
            {nhsData?.retrievingNhsMedicalRecords &&
            !patient?.nhsRecordsObtained
              ? "Retrieving medical records from NHS login"
              : "We need more information from you"}
          </Typography>
          {summarySubtext}
          <Card>
            <CardContent>
              <Stack spacing={2}>
                {summary}
                <Typography variant="body1">
                  If you have any questions about what additional information we
                  require, please contact{" "}
                  <Link href="mailto:patientsupport@curaleafclinic.com">
                    Patient Support
                  </Link>
                  .
                </Typography>
                <Dropzone multiple={true} onDrop={onDrop} />
                <List>
                  {files.map((file, index) => (
                    <ListItem
                      key={`${file.name}-${index}`}
                      secondaryAction={
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() =>
                            setFiles((values) =>
                              values.filter((value) => value !== file),
                            )
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={file.name} />
                    </ListItem>
                  ))}
                </List>
                <Typography variant="h6">Uploaded files</Typography>
                <List>
                  {assistedReferral?.status !== "Promoted to triage"
                    ? arDocuments?.map((filename, index) => (
                        <ListItem key={`${filename}-${index}`}>
                          <ListItemText primary={filename} />
                        </ListItem>
                      ))
                    : null}
                  {assistedReferral?.status === "Promoted to triage"
                    ? pcDocuments?.map((filename, index) => (
                        <ListItem key={`${filename}-${index}`}>
                          <ListItemText primary={filename} />
                        </ListItem>
                      ))
                    : null}
                </List>
              </Stack>
            </CardContent>
            <CardActions>
              <LoadingButton
                fullWidth
                disabled={
                  assistedReferral === undefined ||
                  patient === undefined ||
                  files.length === 0
                }
                loading={loading}
                onClick={onUpload}
                variant="contained"
              >
                Upload files
              </LoadingButton>
            </CardActions>
          </Card>
        </Stack>
      }
      title="Reviewing Records"
    />
  );
};

export default MoreInformationRequired;
