import { createElement as h, ReactElement, useContext } from "react";
import { DefaultParams, Redirect, RouteProps, useRoute } from "wouter";

import { AuthContext } from "src/AuthContext";
import Loader from "src/components/Onboarding/Loader";

type SwitchedProps<T extends DefaultParams> = {
  match?: any;
} & RouteProps<T>;

const PublicRoute = <T extends DefaultParams>({
  path,
  match,
  component,
  children,
}: SwitchedProps<T>): ReactElement | null => {
  const { user } = useContext(AuthContext);
  const useRouteMatch = useRoute(path as string);

  // `props.match` is present - Route is controlled by the Switch
  const [matches, params] = match || useRouteMatch;
  if (!matches) return null;

  if (user === undefined) {
    return <Loader />;
  } else if (user !== null) {
    return <Redirect to="/onboarding/" />;
  } else {
    // React-Router style `component` prop
    if (component) return h(component, { params });

    // support render prop or plain children
    return (
      typeof children === "function" ? children(params) : children
    ) as ReactElement;
  }
};

export default PublicRoute;
