import { ToastContext } from "@curaleaf-international/components";
import LoadingButton from "@mui/lab/LoadingButton";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useContext, useRef, useState } from "react";

import SignatureBox, {
  NoSignatureError,
} from "src/components/Onboarding/SignatureBox";
import {
  useAssistedReferralQuery,
  useSetSCRPermissionMutation,
  useUploadSCRSignatureMutation,
} from "src/queries";

const ConsentCard = () => {
  const { addToast } = useContext(ToastContext);
  const [loading, setLoading] = useState(false);
  const ref = useRef<any>({});
  const { data: assistedReferral } = useAssistedReferralQuery();
  const { mutateAsync: setPermission } = useSetSCRPermissionMutation();
  const { mutateAsync: upload } = useUploadSCRSignatureMutation();

  if (
    assistedReferral?.status === "Promoted to triage" ||
    (assistedReferral?.socPermission && assistedReferral?.ncrsPermission)
  ) {
    return null;
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      const blob = await ref.current();
      await upload(blob);
      await setPermission();
    } catch (error) {
      if (error instanceof NoSignatureError) {
        addToast("Please enter a signature", "error");
      } else {
        addToast("Please try again", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardContent>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="body2">
            To give us permission to request your medical records directly from
            your GP or the NHS National Care Record Service, please sign below.
            This will help speed up your assessment.
          </Typography>
          <Typography variant="body2">
            Ensure your signature is complete to avoid delays.
          </Typography>
          <SignatureBox
            defaultSignature={
              assistedReferral !== undefined
                ? `${assistedReferral.firstName} ${assistedReferral.lastName}`
                : undefined
            }
            ref={ref}
          />
        </Stack>
      </CardContent>
      <CardActions>
        <LoadingButton
          fullWidth
          loading={loading}
          onClick={onSubmit}
          variant="contained"
        >
          Submit signature
        </LoadingButton>
      </CardActions>
    </Card>
  );
};

export default ConsentCard;
