import type { Components } from "@mui/material/styles/components";

export const createComponents = (): Components => {
  return {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "8px",
          padding: "10px 18px",
          gap: "6px",
        },
      },
    },
    MuiCard: {
      variants: [
        {
          props: { variant: "landing" },
          style: {
            borderRadius: "12px",
          },
        },
        {
          props: { variant: "onboarding" },
          style: {
            borderRadius: "8px",
          },
        },
      ],
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: "landingH6",
        },
      },
      styleOverrides: {
        root: {
          padding: "32px 24px 16px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "1rem",
          lineHeight: 1.4,
          padding: "18px 24px",
          borderRadius: "100px",
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          height: "1px",
          borderRadius: "2px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        sizeSmall: {
          padding: 4,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
        },
      },
    },
  };
};
